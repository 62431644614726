import { Box, Button, ButtonGroup } from '@chakra-ui/react';
import { SnippetBox } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { useState } from 'react';
import dedent from 'ts-dedent';

const { alerts } = i18n.pages.detections;

interface PayloadTabsProps {
  isModal?: boolean;
  payload: string | null;
}

function transform_payload(payload: string, transform_into = 'base64') {
  if (payload) {
    const payload_buffer = Buffer.from(payload, 'base64');
    switch (transform_into) {
      case alerts.suricata.payload.hex:
        payload = Array.from(payload_buffer)
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('');
        break;
      case alerts.suricata.payload.ascii:
        payload = payload_buffer.toString('ascii');
        break;
      default:
        break;
    }
  }
  return payload;
}

export const PayloadTabs = ({ payload, isModal }: PayloadTabsProps) => {
  const [activeView, setActiveView] = useState<string>('Ascii');
  const [position, setPosition] = useState(0);

  const titles = [
    alerts.suricata.payload.ascii,
    alerts.suricata.payload.hex,
    alerts.suricata.payload.base64,
  ];

  const payloadContents = [
    transform_payload(payload ?? '', alerts.suricata.payload.ascii),
    transform_payload(payload ?? '', alerts.suricata.payload.hex),
    transform_payload(payload ?? '', alerts.suricata.payload.base64),
  ];
  return (
    <Box maxWidth={isModal ? '100%' : '365px'}>
      <ButtonGroup
        isAttached
        data-testid="suricata-payload-button-group"
        mb={2}
      >
        {titles.map((title, index) => {
          return (
            <Button
              variant="solid"
              onClick={() => {
                setPosition(index);
                setActiveView(title);
              }}
              colorScheme={activeView == title ? 'blue' : 'gray'}
              key={index}
            >
              {title}
            </Button>
          );
        })}
      </ButtonGroup>
      <SnippetBox
        snippet={dedent`${payloadContents[position]}`}
        isCopyable={true}
        overflow="scroll"
        height={!isModal ? '160px' : 'auto'}
      />
    </Box>
  );
};
