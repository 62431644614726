import {
  Button,
  Circle,
  Flex,
  TabProps,
  TabsProps,
  useTab,
  useTabsStyles,
} from '@chakra-ui/react';
import React from 'react';

import { useScrollTabIntoView } from '../../hooks';

export const IndicatorTab = React.forwardRef<
  HTMLButtonElement,
  TabProps & {
    status?: 'dirty' | 'invalid';
    orientation?: TabsProps['orientation'];
  }
>((props, ref) => {
  const tabProps = useTab({ ...props, ref });
  const { status } = props;
  const styles = useTabsStyles();

  const {
    'aria-controls': _, // Generated id is not useful and triggers axe-core.
    ...scrollProps
  } = useScrollTabIntoView({
    tabProps,
  });

  return (
    <Button __css={styles.tab} {...scrollProps}>
      <Flex
        alignItems="center"
        justifyContent={props.orientation === 'vertical' ? 'start' : 'center'}
        textAlign={props.orientation === 'vertical' ? 'left' : 'center'}
        gap={3}
      >
        {tabProps.children}
        {status && (
          <Circle
            bg={status === 'dirty' ? 'corelight.200' : 'yellow.400'}
            size={2}
            data-testid="gamma-indicator-tab-status"
          />
        )}
      </Flex>
    </Button>
  );
});
