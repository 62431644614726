import { Box, TabList, Tabs } from '@chakra-ui/react';
import { MouseEvent as ReactMouseEvent, ReactNode, useMemo } from 'react';
import {
  matchPath,
  resolvePath,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { IndicatorTab } from '../Components';
import { TabsProps } from '../Tabs';

export interface NavTab {
  title: ReactNode;
  to: string;
  isDisabled?: boolean;
  key?: string;
  id?: string;
  'data-testid'?: string;
  status?: 'dirty' | 'invalid';
  afterLabel?: ReactNode;
  onClick?: (e: ReactMouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface NavTabsProps {
  tabs: NavTab[];
  basePath: string;
  px?: number;
  isFitted?: boolean;
  orientation?: TabsProps['orientation'];
  variant?: string;
  size?: string;
  'data-testid'?: string;
  'aria-label'?: string;
}

export const NavTabs = ({
  tabs,
  basePath,
  isFitted = true,
  orientation = 'horizontal',
  variant,
  size,
  px,
  ...rest
}: NavTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTabLocationIndex = useMemo(() => {
    return tabs.findIndex(({ to }) => {
      const resolvedTo = resolvePath(to, basePath).pathname;
      const matchedPath = matchPath(
        { path: resolvedTo, end: false },
        location.pathname,
      );
      return matchedPath;
    });
  }, [basePath, location.pathname, tabs]);

  // this padding and margins allows overflow scroll while allowing room for
  // focus outline and scroll bar
  const overflowStyling =
    orientation === 'horizontal'
      ? {
          p: '2px',
          m: '-2px',
          w: 'calc(100% + 4px)',
        }
      : undefined;

  return (
    <Tabs
      isFitted={orientation === 'vertical' ? false : isFitted}
      index={currentTabLocationIndex}
      orientation={orientation}
      variant={variant}
      size={size}
      w="100%"
    >
      <Box w="full" {...overflowStyling} overflow="auto" display="flex">
        <TabList as="nav" {...rest} flex="1" px={px}>
          {tabs.map(
            (
              {
                title,
                to,
                key,
                isDisabled = false,
                afterLabel,
                onClick,
                ...rest
              },
              index,
            ) => (
              <IndicatorTab
                isDisabled={isDisabled}
                key={key ?? index}
                onClick={(e) => {
                  if (onClick) {
                    onClick(e);
                  }
                  if (!e.defaultPrevented) {
                    navigate(to, { preventScrollReset: true });
                  }
                }}
                orientation={orientation}
                {...rest}
              >
                {title}
                {afterLabel}
              </IndicatorTab>
            ),
          )}
        </TabList>
      </Box>
    </Tabs>
  );
};
