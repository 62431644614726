import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Link,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  StyleProps,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { RouterLink } from '../../../RouterLink';
import { SidebarContext } from '../../Sidebar';

export interface SidebarFooterItemProps {
  href?: string;
  icon: ReactNode;
  children: ReactNode;
  target?: string;
  exact?: boolean;
  isDisabled?: boolean;
  isExternal?: boolean;
  popoverContent?: ReactNode;
  ['data-testid']?: string;
  /**
   * use false for items that are not clickable but need to inherit the
   * open/closed behaviors of other sidebar items
   */
  isInteractive?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
}

const buttonProps = {
  justifyContent: 'start',
  height: 'unset',
};

export const SidebarFooterItem = ({
  children,
  href,
  icon,
  exact,
  isDisabled = false,
  isExternal = false,
  target = '_blank',
  'data-testid': dataTestId,
  isInteractive = true,
  popoverContent,
  ...mouseEvents
}: SidebarFooterItemProps) => {
  const { isOpen } = useContext(SidebarContext);
  const disabledTextColor = useColorModeValue('gray.400', 'gray.600');
  const itemTextColor = useColorModeValue('gray.700', 'gray.200');
  const itemHoverTextColor = useColorModeValue('gray.900', 'gray.50');

  const LinkComp = useMemo(
    () => (isExternal ? Link : RouterLink),
    [isExternal],
  );

  const getColor = useCallback(
    (type: 'icon' | 'listItem' | 'listItemHover') => {
      const colors = {
        icon: {
          disabled: disabledTextColor,
          interactive: 'state.selected',
          default: 'text.secondary',
        },
        listItem: {
          disabled: disabledTextColor,
          interactive: itemTextColor,
          default: 'text.secondary',
        },
        listItemHover: {
          disabled: disabledTextColor,
          interactive: itemHoverTextColor,
          default: 'text.secondary',
        },
      };

      switch (true) {
        case isDisabled: {
          return colors[type].disabled;
        }

        case isInteractive: {
          return colors[type].interactive;
        }

        default: {
          return colors[type].default;
        }
      }
    },
    [],
  );

  const openContents = (
    <Flex gap={3}>
      <Center
        display="inline-flex"
        height={5}
        width={5}
        ml={0.5}
        color={getColor('icon')}
      >
        {icon}
      </Center>
      {isOpen ? (
        <chakra.span overflow="hidden">{children}</chakra.span>
      ) : undefined}
    </Flex>
  );

  const styleProps: Record<string, StyleProps> = {
    isOpen: {
      overflow: 'hidden',
      py: '2',
      px: '3',
      minW: '40px',
      minH: '40px',
      w: '100%',
      display: 'flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
    isClosed: {
      py: 2,
      px: 3,
      w: '100%',
      textStyle: 'body-md',
    },
  };

  return (
    <Popover
      trigger="hover"
      placement="end"
      isOpen={popoverContent ? undefined : isOpen ? false : undefined}
    >
      <PopoverTrigger>
        <ListItem
          position="relative"
          display="flex"
          alignItems="center"
          justifyContent={isOpen ? '' : 'center'}
          color={getColor('listItem')}
          data-testid={dataTestId}
          _hover={{
            color: getColor('listItemHover'),
          }}
          _after={{
            content: `' '`,
            position: 'absolute',
            top: 2,
            bottom: 2,
            left: 0,
            width: '3px',
            background: 'transparent',
            borderTopRightRadius: 'base',
            borderBottomRightRadius: 'base',
          }}
        >
          {isInteractive ? (
            <LinkComp
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault();
                }
              }}
              as={href ? undefined : Button}
              to={href ?? ''}
              href={href}
              target={target}
              {...(!href && buttonProps)}
              {...mouseEvents}
              cursor={isDisabled ? 'not-allowed' : 'pointer'}
              _hover={{
                textDecor: 'none',
              }}
              {...styleProps['isOpen']}
            >
              {openContents}
            </LinkComp>
          ) : (
            <Box {...styleProps['isOpen']} cursor="default">
              {openContents}
            </Box>
          )}
        </ListItem>
      </PopoverTrigger>
      <Portal>
        {popoverContent ??
          (!isOpen && (
            <PopoverContent>
              {isInteractive ? (
                <LinkComp
                  as={href ? undefined : Button}
                  onClick={(e) => {
                    if (isDisabled) {
                      e.preventDefault();
                    }
                  }}
                  to={href ?? ''}
                  href={href}
                  target={target}
                  {...(!href && buttonProps)}
                  {...mouseEvents}
                  color={isDisabled ? disabledTextColor : ''}
                  cursor={isDisabled ? 'not-allowed' : 'pointer'}
                  {...styleProps['isClosed']}
                >
                  {children}
                </LinkComp>
              ) : (
                <Box {...styleProps['isClosed']} cursor="default">
                  {children}
                </Box>
              )}
            </PopoverContent>
          ))}
      </Portal>
    </Popover>
  );
};
