import {
  Center,
  chakra,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useMultiStyleConfig,
  VisuallyHidden,
} from '@chakra-ui/react';
import {
  FocusEventHandler,
  MouseEventHandler,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import {
  Link,
  matchPath,
  useLocation,
  useMatch,
  useResolvedPath,
} from 'react-router-dom';
import { SidebarContext } from '../../Sidebar';

export interface SidebarItemProps {
  to: string;
  icon: ReactNode;
  children: ReactNode;
  state?: any;
  replace?: boolean;
  reloadDocument?: boolean;
  exact?: boolean;
  altPath?: string;
  isDisabled?: boolean;
  ['data-testid']?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  onFocus?: FocusEventHandler<HTMLAnchorElement>;
  onBlur?: FocusEventHandler<HTMLAnchorElement>;
}

const RouterLink = chakra(Link);

export const SidebarItem = ({
  children,
  to,
  icon,
  exact,
  altPath,
  isDisabled = false,
  'data-testid': dataTestId,
  ...rest
}: SidebarItemProps) => {
  const {
    isOpen: isSidebarOpen,
    setIsOpen,
    autoCloseSidebar,
  } = useContext(SidebarContext);
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: exact });
  const { pathname: currentPath } = useLocation();
  const isSubMenuInDataPath = useMemo(() => {
    const subPathPattern = `${altPath}/*`;
    return Boolean(matchPath(subPathPattern, currentPath)) || Boolean(match);
  }, [altPath, currentPath, match]);

  const styles = useMultiStyleConfig('SidebarItem', {
    expanded: isSidebarOpen,
    match: isSubMenuInDataPath,
    isDisabled,
  });

  return (
    <Popover trigger="hover" placement="end">
      <PopoverTrigger>
        <chakra.li __css={{ ...styles.container }} data-testid={dataTestId}>
          <RouterLink
            onClick={(e) => {
              if (isDisabled) {
                e.preventDefault();
              }
              autoCloseSidebar && setIsOpen(false);
            }}
            to={to}
            {...rest}
            __css={styles.link}
          >
            <Flex gap={3}>
              <Center __css={styles.icon}>{icon}</Center>
              {((children) =>
                isSidebarOpen ? (
                  children
                ) : (
                  <VisuallyHidden>{children}</VisuallyHidden>
                ))(
                <chakra.span
                  overflow="hidden"
                  data-testid={`${dataTestId}-label`}
                >
                  {children}
                </chakra.span>,
              )}
            </Flex>
          </RouterLink>
        </chakra.li>
      </PopoverTrigger>
      <Portal>
        {!isSidebarOpen && (
          <PopoverContent __css={styles.popover}>
            <RouterLink
              onClick={(e) => {
                if (isDisabled) {
                  e.preventDefault();
                }
              }}
              to={to}
              {...rest}
              __css={styles.popoverLink}
            >
              {children}
            </RouterLink>
          </PopoverContent>
        )}
      </Portal>
    </Popover>
  );
};
