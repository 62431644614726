import { ComponentStyleConfig } from '@chakra-ui/react';
import { bodyStyles } from '../../common';

export const SidebarItemStyles: ComponentStyleConfig = {
  parts: ['container', 'link', 'icon', 'popover', 'popoverLink'],
  baseStyle: (props) => {
    return {
      container: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: props.expanded ? '' : 'center',
        background: props.match
          ? 'color-mix(in srgb, var(--chakra-colors-gray-400), transparent 90%)'
          : 'transparent',
        _hover: {
          background: props.match ? undefined : 'layer.2',
        },
        _after: {
          content: `""`,
          display: 'inline-block',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          width: '3px',
          background: props.match ? 'state.selected' : 'transparent',
        },
      },
      link: {
        display: 'flex',
        alignItems: 'center',
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        py: 2.5,
        px: 3.5,
        minW: 10,
        minH: 10,
        w: 'full',
        whiteSpace: 'nowrap',
        color: props.match
          ? 'text.link'
          : props.isDisabled
            ? 'text.disabled'
            : 'text.primary',
        _hover: {
          color: props.match
            ? 'text.link'
            : props.isDisabled
              ? 'text.disabled'
              : 'text.primary',
        },
      },
      icon: {
        display: 'inline-flex',
        minW: 5,
        minH: 5,
        height: 5,
        width: 5,
        color: props.match
          ? 'inherit'
          : props.isDisabled
            ? 'text.disabled'
            : 'gray.400',
      },
      popover: {
        w: '200px',
      },
      popoverLink: {
        w: 'full',
        py: 2,
        px: 3,
        color: props.isDisabled ? 'text.disabled' : '',
        cursor: props.isDisabled ? 'not-allowed' : 'pointer',
        ...bodyStyles['body-md'],
      },
    };
  },
  defaultProps: {
    expanded: true,
    match: false,
    isDisabled: false,
  },
};
