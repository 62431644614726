import {
  Box,
  ButtonGroup,
  Flex,
  HStack,
  Portal,
  Stack,
  Text,
  useDisclosure,
  useStyles,
} from '@chakra-ui/react';
import { getColumnFlexValue } from '@gamma/util';
import {
  Draggable,
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
} from '@hello-pangea/dnd';
import { isEqual } from 'lodash';
import { ComponentType, MouseEvent, ReactNode, memo, useMemo } from 'react';
import {
  Column,
  ColumnInstance,
  HeaderGroup,
  IdType,
  Row,
  TableState,
} from 'react-table';

import { DataTableProps } from '../../DataTable';

export interface DataTableRowProps<DataType extends Record<string, unknown>>
  extends Partial<DraggableProvidedDragHandleProps>,
    Partial<DraggableProvidedDraggableProps> {
  row: Row<DataType>;
  index: number;
  isExpanded?: boolean;
  isSelected?: boolean;
  getOriginalColumn: (
    columnIndex: number,
    column: ColumnInstance<DataType> | HeaderGroup<DataType>,
  ) => Column<DataType> | ColumnInstance<DataType>;
  isDragging?: boolean;
  truncateCells?: boolean;
  onClick?: (row: Row<DataType>, event: MouseEvent<HTMLDivElement>) => void;
  rowIsExpandable?: (row: Row<DataType>) => boolean | string;
  RenderExpandedRow?: ComponentType<Row<DataType>>;
  Wrapper?: ComponentType<DataType & { children: ReactNode }>;
  innerRef?: React.ForwardedRef<HTMLDivElement>;
  UNSAFE_isDraggable?: boolean;
  columnResizing?: TableState<DataType>['columnResizing'];
  values?: Record<IdType<DataType>, any>;
  hiddenColumns?: IdType<DataType>[];
  RowActions?: ComponentType<DataType>;
  ExpandedRowActions?: ComponentType<DataType>;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  memoProps?: DataTableProps<DataType>['memoProps'];
}

const alignmentMap = new Map<'left' | 'right' | 'center', string>([
  ['left', 'flex-start'],
  ['right', 'flex-end'],
  ['center', 'center'],
]);

const UnmemodDataTableRow = <DataType extends Record<string, unknown>>({
  row,
  index,
  isDragging = false,
  truncateCells,
  getOriginalColumn,
  onClick,
  rowIsExpandable = () => true,
  RenderExpandedRow,
  Wrapper = Box,
  isExpanded,
  isSelected,
  innerRef,
  columnResizing,
  values,
  RowActions,
  ExpandedRowActions,
  size = 'md',
  memoProps,
  ...rest
}: DataTableRowProps<DataType>) => {
  const isExpandable = useMemo(() => {
    const result = rowIsExpandable?.(row);
    return typeof result === 'string' ? true : result;
  }, [row, rowIsExpandable]);

  const styles = useStyles();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const actionButtonSize = useMemo(() => {
    return ['xs', 'sm'].includes(size) ? 'xs' : 'sm';
  }, [size]);

  const rowActionButtons = useMemo(
    () =>
      (RowActions || ExpandedRowActions) && (
        <ButtonGroup
          variant="solid"
          colorScheme="gray"
          size={isExpanded ? actionButtonSize : `box-${actionButtonSize}`}
          data-button-size={actionButtonSize}
          spacing={2}
          {...(!isExpanded ? { __css: styles.rowActions } : {})}
        >
          {isExpanded && ExpandedRowActions ? (
            <ExpandedRowActions {...row.original} />
          ) : RowActions ? (
            <RowActions {...row.original} />
          ) : null}
        </ButtonGroup>
      ),
    [
      RowActions,
      ExpandedRowActions,
      styles.rowActions,
      isExpanded,
      actionButtonSize,
    ],
  );

  const { key, ...rowProps } = row.getRowProps();

  return (
    <Stack
      __css={styles.row}
      ref={innerRef}
      data-testid={`table-row-${index}-wrapper`}
      spacing={0}
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <Wrapper {...row.original}>
        <HStack
          spacing={0}
          data-testid={`table-row-${index}`}
          alignItems="stretch"
          key={key}
          {...rowProps}
          {...rest}
          onClick={(event) => onClick?.(row, event)}
          __css={isDragging ? styles.draggedRow : undefined}
        >
          {row.cells.map((cell, index) => {
            const { style = {}, key, ...cellProps } = cell.getCellProps();
            const { flex, ...cellStyles } = style;

            // get the original column object for the cell
            const originalColumn = getOriginalColumn(index, cell.column);

            const isExpandOrSelectColumn = ['expanded', 'selection'].includes(
              originalColumn.id ?? '',
            );

            const extraCellProps = {
              p: 0,
              justifyContent: 'center',
              onClick: (e: MouseEvent) => e.stopPropagation(),
            };

            return (
              <Flex
                key={key}
                {...cellProps}
                // @ts-ignore we want this to be overwritten, it's not _always_ overwritten though, ts is just dramatic
                justifyContent={alignmentMap.get(cell.column.align ?? 'left')}
                {...(isExpandOrSelectColumn ? extraCellProps : {})}
                style={cellStyles}
                flex={getColumnFlexValue(cell.column.width, originalColumn)}
                __css={styles.cell}
                minW={originalColumn.minWidth}
                maxW={originalColumn.maxWidth}
                data-testid="data-table-cell"
              >
                {truncateCells ? (
                  // margin and padding allow outer borders of contents to not be
                  // hidden by overflow
                  <Text
                    as="span"
                    isTruncated
                    p="2px"
                    m="-2px"
                    display={isExpandOrSelectColumn ? 'flex' : undefined}
                  >
                    {cell.render('Cell')}
                  </Text>
                ) : (
                  cell.render('Cell')
                )}
              </Flex>
            );
          })}
        </HStack>
      </Wrapper>
      {(RowActions || ExpandedRowActions) && isOpen && (
        <Flex
          data-testid={`table-row-${index}-actions-wrapper`}
          __css={
            isExpanded
              ? {
                  ml: 2,
                }
              : {
                  ...styles.rowActionsWrapper,
                }
          }
        >
          {rowActionButtons}
        </Flex>
      )}
      {isExpandable && isExpanded && RenderExpandedRow && (
        <Flex role="row" __css={styles.expandedRow}>
          <div role="cell" style={{ display: 'contents' }}>
            {<RenderExpandedRow {...row} />}
          </div>
        </Flex>
      )}
    </Stack>
  );
};

export const DraggableDTRow = <DataType extends Record<string, unknown>>({
  UNSAFE_isDraggable,
  row,
  hiddenColumns,
  memoProps,
  ...rest
}: DataTableRowProps<DataType>) => {
  return (
    <Draggable
      key={row.id}
      draggableId={row.id}
      index={row.index}
      isDragDisabled={!UNSAFE_isDraggable || row.isExpanded}
    >
      {(provided, snapshot) =>
        snapshot.isDragging ? (
          <Portal>
            <UnmemodDataTableRow
              {...rest}
              row={row}
              innerRef={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              isDragging={true}
              isExpanded={row.isExpanded}
            />
          </Portal>
        ) : (
          <UnmemodDataTableRow
            row={row}
            {...rest}
            innerRef={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isExpanded={row.isExpanded}
          />
        )
      }
    </Draggable>
  );
};

export const MemodDraggableDTRow = memo(DraggableDTRow, (prev, next) => {
  /**
   * We can't check that the `row` prop has changed because for some reason
   * react-table always gives us the SAME ROW FOR PREV AND NEXT. So, I added
   * all these extra props that aren't actually needed for rendering but help
   * in this memo equality check
   */
  const isResizing = !isEqual(prev.columnResizing, next.columnResizing);
  const isExpanded = prev.isExpanded !== next.isExpanded;
  const isSelected = prev.isSelected !== next.isSelected;
  const isColumnVisibilityChanging =
    prev.hiddenColumns?.length !== next.hiddenColumns?.length;
  const isSizeChanged = prev.size !== next.size;

  /**
   * we DO NOT check `row.original` because that includes the entire obj passed
   * to the row. Instead we check `row.values` because that only includes the
   * values that are actively being used in the table columns
   */
  let dataIsChanged = false;
  if (next.memoProps) {
    dataIsChanged = next.memoProps.some((prop) => {
      const tProp = prop as keyof typeof next.values;
      return !isEqual(prev.values?.[tProp], next.values?.[tProp]);
    });
  } else {
    dataIsChanged = !isEqual(prev.values, next.values);
  }

  if (
    isResizing ||
    isExpanded ||
    isSelected ||
    dataIsChanged ||
    isColumnVisibilityChanging ||
    isSizeChanged
  ) {
    return false;
  }

  return true;
}) as <DataType extends Record<string, unknown>>(
  props: DataTableRowProps<DataType>,
) => ReturnType<typeof DraggableDTRow>;
