import { HStack, useStyles } from '@chakra-ui/react';
import { Column, ColumnInstance, HeaderGroup, SortingRule } from 'react-table';
import { DataTableColumnHeader } from '../DataTableColumnHeader';

export interface DataTableColumnHeadersProps<
  DataType extends Record<string, unknown>,
> {
  headerGroups: HeaderGroup<DataType>[];
  getOriginalColumn: (
    columnIndex: number,
    column: ColumnInstance<DataType> | HeaderGroup<DataType>,
  ) => Column<DataType> | ColumnInstance<DataType>;
  onColumnSort?: (columnSort: SortingRule<DataType>) => void;
  'data-testid'?: string;
}

export const DataTableColumnHeaders = <
  DataType extends Record<string, unknown>,
>({
  headerGroups,
  getOriginalColumn,
  onColumnSort,
  'data-testid': dataTestId,
}: DataTableColumnHeadersProps<DataType>) => {
  const styles = useStyles();

  return (
    <>
      {headerGroups.map((headerGroup) => {
        const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <HStack
            key={key}
            {...headerGroupProps}
            __css={styles.headerGroup}
            gap={0}
            data-testid={`${dataTestId}-header-group`}
          >
            {headerGroup.headers.map((column, index) => {
              // get the original column object for the cell
              const originalColumn = getOriginalColumn(index, column);

              return (
                <DataTableColumnHeader
                  column={column}
                  originalColumn={originalColumn}
                  isLast={index === headerGroup.headers.length - 1}
                  onColumnSort={onColumnSort}
                  data-testid={`${dataTestId}-column-header`}
                  key={column.id}
                />
              );
            })}
          </HStack>
        );
      })}
    </>
  );
};
