import { ApolloError } from '@apollo/client';
import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ROUTES } from '@gamma/investigator/constants';
import { SlideDrawerContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  Detection,
  ExcludedEntity,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { Popover } from '@gamma/overlay';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
  DetectionEntityEDRPanel,
  DetectionEntityPanel,
} from '../../../../Components';
import { IDetectionContext } from '../../../Detection/Detection';

const { systemSettingsIntegrationsConfig } = ROUTES;

const { detection: detectionText, entityEDR } = i18n.pages.detections;

const { source, destination, entity } = detectionText;

export interface DetectionEntitiesProps {
  detectionInfo?: Detection;
  activeEntity: AlertEntity | null;
  setActiveEntity: Dispatch<SetStateAction<AlertEntity | null>>;
  excludeEntitiesData?: ExcludedEntity[];
  excludeEntitiesError?: ApolloError;
  queryVariables?: {
    query: string;
    offset: number;
    size: number;
    sort: {
      sort_by: string;
      sort_dir: string;
    }[];
  };
}

export const DetectionEntities = ({
  detectionInfo,
  activeEntity,
  setActiveEntity,
  queryVariables,
  excludeEntitiesData,
  excludeEntitiesError,
}: DetectionEntitiesProps) => {
  const navigate = useNavigate();

  const { edrConfig } = useOutletContext<IDetectionContext>() || {};

  const {
    portalType,
    setPortalType,
    slideDrawerTitle,
    slideDrawerBody,
    setIsSlideDrawerOpen,
    setSlideDrawerOnClose,
  } = useContext(SlideDrawerContext);

  const { paginated_destination_entities, paginated_source_entities } =
    detectionInfo || {};

  const [entityType, setEntityType] = useState<string>();

  const sourceEntities = paginated_source_entities?.alert_entities;
  const totalSourceEntities = paginated_source_entities?.total_items;
  const destinationEntities = paginated_destination_entities?.alert_entities;
  const totalDestinationEntities = paginated_destination_entities?.total_items;

  const hasSource = Boolean(sourceEntities?.length);
  const hasDestination = Boolean(destinationEntities?.length);

  const NUM_ENTITIES_SHOWN = 2;
  const sourceEntitiesOverflow =
    sourceEntities && sourceEntities.length - NUM_ENTITIES_SHOWN;
  const destinationEntitiesOverflow =
    destinationEntities && destinationEntities.length - NUM_ENTITIES_SHOWN;

  const entities =
    entityType === 'source'
      ? sourceEntities
      : entityType === 'destination'
        ? destinationEntities
        : undefined;

  const totalEntites =
    entityType === 'source'
      ? totalSourceEntities
      : entityType === 'destination'
        ? totalDestinationEntities
        : undefined;

  useEffect(() => {
    setSlideDrawerOnClose?.(() => () => {
      setActiveEntity(null);
      setEntityType(undefined);
    });
  }, [setSlideDrawerOnClose, setIsSlideDrawerOpen, setActiveEntity]);

  const setEntityDrawerOpen = ({
    entity,
    entitySrcDest,
  }: {
    entity: AlertEntity;
    entitySrcDest: string;
  }) => {
    setPortalType?.('entity');
    setActiveEntity(entity);
    setEntityType(entitySrcDest);
    setIsSlideDrawerOpen?.(true);
  };

  return (
    <AccordionPanel
      w="100%"
      title={
        <HStack>
          <Text>{entity}</Text>
          {!edrConfig?.enabled && (
            <Box h="20px">
              <Popover
                isLazy
                width="320px"
                trigger="hover"
                placement="right"
                title={entityEDR.entityEnrichment}
                body={entityEDR.getEntityEnrichment}
                footer={
                  <Button
                    size="md"
                    variant="solid"
                    colorScheme="blue"
                    rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
                    onClick={() => {
                      navigate({
                        pathname: systemSettingsIntegrationsConfig,
                      });
                    }}
                  >
                    {entityEDR.integrationPage}
                  </Button>
                }
              >
                <MuiIcon color="text.secondary">info</MuiIcon>
              </Popover>
            </Box>
          )}
        </HStack>
      }
      reduceMotion={true}
      storageKey="detection-details-entity"
    >
      <HStack alignItems="start">
        <VStack w="50%" alignItems="start">
          <HStack>
            <MuiIcon size="lg" color={hasSource ? 'text.primary' : 'gray'}>
              crisis_alert
            </MuiIcon>
            <Text
              as="h5"
              textStyle="h5"
              color={hasSource ? 'text.primary' : 'gray'}
            >
              {source}
              {sourceEntities &&
              sourceEntities.length !== 1 &&
              totalSourceEntities
                ? ` (${sourceEntities?.length}${totalSourceEntities > sourceEntities?.length ? ` of ${totalSourceEntities}` : ''})`
                : ''}
            </Text>
          </HStack>
          {sourceEntities?.slice(0, NUM_ENTITIES_SHOWN).map((entity, index) => (
            <Button
              size="xs"
              key={index}
              variant="solid"
              colorScheme="gray"
              onClick={() => {
                setEntityDrawerOpen({
                  entity,
                  entitySrcDest: 'source',
                });
              }}
            >
              {entity.entity_name}
            </Button>
          ))}
          {sourceEntitiesOverflow && sourceEntitiesOverflow > 0 && (
            <Button
              size="xs"
              variant="solid"
              colorScheme="blue"
              onClick={() => {
                setEntityDrawerOpen({
                  entity: sourceEntities[NUM_ENTITIES_SHOWN],
                  entitySrcDest: 'source',
                });
              }}
            >
              +{sourceEntitiesOverflow}
            </Button>
          )}
        </VStack>
        <VStack w="50%" alignItems="start">
          <HStack>
            <MuiIcon size="lg" color={hasDestination ? 'text.primary' : 'gray'}>
              location_on
            </MuiIcon>
            <Text
              as="h5"
              textStyle="h5"
              color={hasDestination ? 'text.primary' : 'gray'}
            >
              {destination}
              {destinationEntities &&
              destinationEntities.length !== 1 &&
              totalDestinationEntities
                ? ` (${destinationEntities.length}${totalDestinationEntities > destinationEntities.length ? ` of ${totalDestinationEntities}` : ''})`
                : ''}
            </Text>
          </HStack>
          <HStack>
            {destinationEntities
              ?.slice(0, NUM_ENTITIES_SHOWN)
              .map((entity, index) => (
                <Button
                  size="xs"
                  key={index}
                  variant="solid"
                  colorScheme="gray"
                  onClick={() => {
                    setEntityDrawerOpen({
                      entity,
                      entitySrcDest: 'destination',
                    });
                  }}
                >
                  {entity.entity_name}
                </Button>
              ))}
            {destinationEntitiesOverflow && destinationEntitiesOverflow > 0 && (
              <Button
                size="xs"
                variant="solid"
                colorScheme="blue"
                onClick={() => {
                  setEntityDrawerOpen({
                    entity: destinationEntities[NUM_ENTITIES_SHOWN],
                    entitySrcDest: 'destination',
                  });
                }}
              >
                +{destinationEntitiesOverflow}
              </Button>
            )}
          </HStack>
        </VStack>
      </HStack>
      {portalType === 'entity' &&
        entityType &&
        activeEntity &&
        slideDrawerTitle &&
        createPortal(
          <HStack __css={{ wordWrap: 'anywhere' }}>
            <Text textTransform="capitalize">{entityType}</Text>
            {entities && entities?.length > 1 && totalEntites && (
              <Text>
                ({entities.length}
                {totalEntites > entities.length ? ` of ${totalEntites}` : ''})
              </Text>
            )}
          </HStack>,
          slideDrawerTitle,
        )}
      {portalType === 'entity' &&
        entityType &&
        activeEntity &&
        slideDrawerBody &&
        createPortal(
          edrConfig && edrConfig?.enabled ? (
            <DetectionEntityEDRPanel
              edrConfig={edrConfig}
              refetchMain={true}
              entities={entities}
              entityInfo={activeEntity}
              detectionInfo={detectionInfo}
              queryVariables={queryVariables}
              setActiveEntity={setActiveEntity}
              excludeEntitiesError={excludeEntitiesError}
              excludeEntitiesData={excludeEntitiesData}
              disableBorders={true}
            />
          ) : (
            <DetectionEntityPanel
              refetchMain={true}
              entities={entities}
              entityInfo={activeEntity}
              detectionInfo={detectionInfo}
              queryVariables={queryVariables}
              setActiveEntity={setActiveEntity}
              excludeEntitiesError={excludeEntitiesError}
              excludeEntitiesData={excludeEntitiesData}
            />
          ),
          slideDrawerBody,
        )}
    </AccordionPanel>
  );
};
