import {
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { i18n } from '@gamma/investigator/localization';
import { Column } from '@gamma/layout';
import { ConnHistoryDetails } from './ConnHistoryDetails';

const { historySummary } = i18n.pages.detections.logscaleDetails;

interface ConnHistorySummaryProps {
  history: string;
}

export const countCharacters = (str: string) => {
  const charCount: Record<string, number> = {};
  for (let i = 0; i < str.length; i++) {
    const char = str[i];
    if (charCount[char]) {
      charCount[char]++;
    } else {
      charCount[char] = 1;
    }
  }

  return charCount;
};

export const ConnHistorySummary = ({ history }: ConnHistorySummaryProps) => {
  let connectionType = '';
  let dataTransfer = '';
  let terminated = '';
  let additionals: string[] = [];
  let historyDetails: string[] = [];
  const index = history.search(/[Rr]/);
  let actualHistory = '';
  if (index == -1) {
    actualHistory = history;
  } else {
    actualHistory = history.slice(0, index + 1);
  }
  const historyUpper = actualHistory.toUpperCase();

  switch (true) {
    case actualHistory.includes('ShA'):
      connectionType = historySummary.connectionType.ShA;
      break;
    case actualHistory.includes('Sh'):
      connectionType = historySummary.connectionType.Sh;
      break;
    case actualHistory.includes('S') && !historyUpper.includes('H'):
      connectionType = historySummary.connectionType.SWithoutH;
      break;
  }

  if (!historyUpper.includes('D')) {
    dataTransfer = historySummary.dataTransfer.noDd;
  } else if (!historyUpper.includes('S') && !historyUpper.includes('H')) {
    if (actualHistory.includes('D') && actualHistory.includes('d')) {
      dataTransfer = historySummary.dataTransfer.DdWithoutSH;
    } else if (actualHistory.includes('D')) {
      dataTransfer = historySummary.dataTransfer.DWithoutSH;
    } else if (actualHistory.includes('d')) {
      dataTransfer = historySummary.dataTransfer.dWithoutSH;
    }
  } else {
    if (actualHistory.includes('D') && actualHistory.includes('d')) {
      dataTransfer = historySummary.dataTransfer.Dd;
    } else if (actualHistory.includes('D')) {
      dataTransfer = historySummary.dataTransfer.D;
    } else if (actualHistory.includes('d')) {
      dataTransfer = historySummary.dataTransfer.d;
    }
  }

  switch (true) {
    case historyUpper.includes('F'):
      terminated = historySummary.terminated.close;
      break;
    case historyUpper.includes('R'):
      terminated = historySummary.terminated.stop;
      break;
  }

  const charCounts = countCharacters(historyUpper);
  const additionalConstants: { [key: string]: string } =
    historySummary.additional;
  Object.keys(charCounts).map((key) => {
    if (additionalConstants[key]) {
      if (['C', 'G', 'T', 'W'].includes(key) && charCounts[key] > 1) {
        const doubleKey = key + key;
        additionals.push(additionalConstants[doubleKey]);
      } else {
        additionals.push(additionalConstants[key]);
      }
    }
  });

  historyDetails = [connectionType, dataTransfer].filter((str) => str !== '');
  historyDetails = historyDetails.concat(additionals);
  if (terminated) {
    historyDetails.push(terminated);
  }
  const joinedHistorySummary = historyDetails.join(', ');

  return (
    <Popover trigger="hover" placement="top-start">
      <PopoverTrigger>
        <Column padding={0}>
          {connectionType && (
            <Text overflowWrap="anywhere">{connectionType}</Text>
          )}
          {dataTransfer && <Text overflowWrap="anywhere">{dataTransfer}</Text>}
          {additionals.length > 0 &&
            additionals.map((additional, index) => (
              <Text overflowWrap="anywhere" key={index}>
                {additional}
              </Text>
            ))}
          {terminated && <Text overflowWrap="anywhere">{terminated}</Text>}
        </Column>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody padding={0}>
          <ConnHistoryDetails
            history={history}
            historySummary={joinedHistorySummary}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
