import { ApolloError } from '@apollo/client';
import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { MuiIcon } from '@gamma/display';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  AlertEntity,
  Detection,
  ExcludedEntity,
} from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { Popover } from '@gamma/overlay';
import { SingleValue } from 'chakra-react-select';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EntityOption, SantizedDetection } from '../../../../Detections';
import { DetectionDrawerEntityHeader } from '../DetectionDrawerEntityEDRPanel/Components';

export interface IDetectionDrawerEntityPanelProps {
  refetchMain?: boolean;
  detectionInfo?: Detection | SantizedDetection;
  excludeEntitiesData?: ExcludedEntity[];
  excludeEntitiesError?: ApolloError;
  queryVariables?: {
    query: string;
    offset: number;
    size: number;
    sort: {
      sort_by: string;
      sort_dir: string;
    }[];
  };
  sourceEntities?: AlertEntity[];
  totalSourceEntities?: number;
  destinationEntities?: AlertEntity[];
  totalDestinationEntities?: number;
  disableBorders?: boolean;
}

const { systemSettingsIntegrationsConfig } = ROUTES;

const { entityEDR } = i18n.pages.detections;
const { entity, entityType } = i18n.pages.entityDetails.excludeEntity;

export const DetectionDrawerEntityPanel = ({
  refetchMain,
  detectionInfo,
  sourceEntities,
  totalSourceEntities,
  destinationEntities,
  totalDestinationEntities,
  disableBorders = false,
}: IDetectionDrawerEntityPanelProps) => {
  const navigate = useNavigate();

  const { isOrgTenant } = useContext(AuthContext);

  const [selectedEntity, setSelectedEntity] = useState<EntityOption | null>(
    null,
  );

  const [selectedSrcOption, setSelectedSrcOption] =
    useState<EntityOption | null>(null);
  const [selectedDestOption, setSelectedDestOption] =
    useState<EntityOption | null>(null);

  const [activeEntityType, setActiveEntityType] = useState<string>('source');

  const sourceOptions: EntityOption[] = sourceEntities?.length
    ? sourceEntities?.map((entity) => {
        return {
          value: entity.entity_id,
          label: entity.entity_name,
          type: entity.entity_type,
        };
      })
    : [
        {
          value: '',
          label: '',
          type: '',
        },
      ];

  const destinationOptions: EntityOption[] = destinationEntities?.length
    ? destinationEntities?.map((entity) => {
        return {
          value: entity.entity_id,
          label: entity.entity_name,
          type: entity.entity_type,
        };
      })
    : [
        {
          value: '',
          label: '',
          type: '',
        },
      ];

  useEffect(() => {
    setSelectedSrcOption({
      value: sourceEntities?.[0]?.entity_id || '',
      label: sourceEntities?.[0]?.entity_name || '',
      type: sourceEntities?.[0]?.entity_type || '',
    });
  }, [sourceEntities]);

  useEffect(() => {
    setSelectedDestOption({
      value: destinationEntities?.[0]?.entity_id || '',
      label: destinationEntities?.[0]?.entity_name || '',
      type: destinationEntities?.[0]?.entity_type || '',
    });
  }, [destinationEntities]);

  const handleSrcEntityChange = (event: SingleValue<EntityOption>) => {
    setSelectedSrcOption(event);
    setSelectedEntity && setSelectedEntity(event);
  };

  const handleDestEntityChange = (event: SingleValue<EntityOption>) => {
    setSelectedDestOption(event);
    setSelectedEntity && setSelectedEntity(event);
  };

  useEffect(() => {
    if (activeEntityType === 'source') {
      setSelectedEntity && setSelectedEntity(selectedSrcOption);
    } else if (activeEntityType === 'destination') {
      setSelectedEntity && setSelectedEntity(selectedDestOption);
    }
  }, [activeEntityType, selectedSrcOption, selectedDestOption]);

  return (
    <Box w="100%" data-testid="detection-drawer-entity-panel">
      <AccordionPanel
        w="100%"
        layerStyle="first"
        reduceMotion={true}
        disableBorders={disableBorders}
        data-testid="entity-panel-heading"
        storageKey="detection-entity-panel"
        title={
          <HStack alignItems="center">
            <Box>{entity}</Box>
            <Box h="20px">
              <Popover
                isLazy
                width="320px"
                trigger="hover"
                placement="right"
                title={entityEDR.entityEnrichment}
                body={
                  isOrgTenant
                    ? entityEDR.getEntityEnrichmentOrg
                    : entityEDR.getEntityEnrichment
                }
                footer={
                  !isOrgTenant && (
                    <Button
                      size="md"
                      variant="solid"
                      colorScheme="blue"
                      rightIcon={<MuiIcon>arrow_forward</MuiIcon>}
                      onClick={() => {
                        navigate({
                          pathname: systemSettingsIntegrationsConfig,
                        });
                      }}
                    >
                      {entityEDR.integrationPage}
                    </Button>
                  )
                }
              >
                <MuiIcon color="text.secondary">info</MuiIcon>
              </Popover>
            </Box>
          </HStack>
        }
        expanded={true}
      >
        <VStack spacing={2.5} alignItems="start">
          <DetectionDrawerEntityHeader
            selectedSrcOption={selectedSrcOption}
            selectedDestOption={selectedDestOption}
            activeEntityType={activeEntityType}
            setActiveEntityType={setActiveEntityType}
            sourceOptions={sourceOptions}
            destinationOptions={destinationOptions}
            totalSourceEntities={totalSourceEntities}
            totalDestinationEntities={totalDestinationEntities}
            handleSrcEntityChange={handleSrcEntityChange}
            handleDestEntityChange={handleDestEntityChange}
          />
          <Flex alignItems="center" w="100%">
            <Box w="120px" mr={2}>
              <Text
                color="text.300"
                data-testid="entity-panel-entity-type-label"
                textStyle="body-md"
              >
                {entityType}
              </Text>
            </Box>
            <Box flexGrow={1}>
              <Text
                data-testid="entity-panel-entity-type-value"
                textStyle="body-md"
              >
                {detectionInfo?.alert_entity?.entity_type}
              </Text>
            </Box>
          </Flex>
        </VStack>
      </AccordionPanel>
    </Box>
  );
};
