import { ApolloError } from '@apollo/client';
import {
  Box,
  Button,
  HStack,
  Heading,
  Text,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { MarkdownRenderer } from '@gamma/display';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext } from '@gamma/investigator/context';
import { useGetHasUserAcceptedAnalytics } from '@gamma/investigator/hooks';
import { i18n } from '@gamma/investigator/localization';
import { initializePendo } from '@gamma/investigator/utilities';
import { AccordionPanel as GammaAccordionPanel, Panel } from '@gamma/layout';
import { GraphQLReqStatus, LoadingSpinner } from '@gamma/progress';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { EnableCookiesModal } from '../../EnableCookiesModal';
import { ChatGPTQuestionPrompt } from '../ChatGPTQuestionPrompt';
import { ChatGPTToolTip } from '../ChatGPTToolTip';
import { SkeletonLoader } from '../SkeletonLoader';

const {
  loadingDescription,
  noDescription,
  requestDescription,
  settings,
  gptOffAnalystMessage,
  gptOffAdminMessage,
  questionPrompt,
  description,
  generatedFromAI,
} = i18n.pages.integrations.chatGPT;

const { systemSettingsIntegrationsConfig } = ROUTES;

export interface ChatGPTPromptCloudPanelProps {
  titleText?: string;
  suricataRuleText?: string;
  descriptionText?: string;
  isLoadingDescription?: boolean;
  loadingDescriptionError?: ApolloError;
  isDescriptionQuickView?: boolean;
  corelightDescription?: string;
  storageKey?: string;
  isAccordion?: boolean;
  showGPTDisabled?: boolean;
  disableBorders?: boolean;
}

export const ChatGPTPromptCloudPanel = ({
  titleText = description,
  descriptionText,
  isLoadingDescription,
  loadingDescriptionError,
  isDescriptionQuickView,
  corelightDescription,
  storageKey,
  isAccordion,
  showGPTDisabled,
  disableBorders = false,
}: ChatGPTPromptCloudPanelProps) => {
  const isDescriptionPanel = !!descriptionText;

  const { user, userRole, userLicense, isGPTEnabled, userPrivilegesLoading } =
    useContext(AuthContext);

  const navigate = useNavigate();

  const getHasUserAcceptedAnalytics = useGetHasUserAcceptedAnalytics();

  const [pendoGuideType, setPendoGuideType] = useState<string>('');

  const {
    onClose: onCloseAnalyticsModal,
    onOpen: onOpenAnalyticsModal,
    isOpen: isEnableCookiesModalOpen,
  } = useDisclosure();

  const pendoRequestMoreGuideName = 'Investigator_GPT_RequestMore';
  const pendoDescriptionGuideName = 'Investigator_GPT_RequestDescription';

  const hasUserAcceptedAnalytics = getHasUserAcceptedAnalytics();

  const updateURLParams = () => {
    const url = new URL(window.location.href);

    if (pendoGuideType === 'request_more') {
      url.searchParams.set('showPendoRequestMoreButtonGuide', 'true');
      navigate(url.pathname.toString() + url.search.toString());
    }

    if (pendoGuideType === 'description') {
      url.searchParams.set('showPendoDescriptionButtonGuide', 'true');
      navigate(url.pathname.toString() + url.search.toString());
    }
  };

  const handleShowPendoGuideByName = (type: 'description' | 'request_more') => {
    if (!hasUserAcceptedAnalytics) {
      setPendoGuideType(type);
      onOpenAnalyticsModal();
    } else {
      if (type === 'description') {
        window?.pendo?.showGuideByName(pendoDescriptionGuideName);
      }

      if (type === 'request_more') {
        window?.pendo?.showGuideByName(pendoRequestMoreGuideName);
      }
    }
  };

  const [urlParamsForPendoGuide, setURLParamsForPendoGuide] =
    useState<string>('');

  // update url params for pendo guide when present in URL
  useEffect(() => {
    if (isGPTEnabled) {
      const url = new URL(window.location.href);
      const showPendoRequestMoreButtonGuide = url.searchParams.get(
        'showPendoRequestMoreButtonGuide',
      );
      const showPendoDescriptionButtonGuide = url.searchParams.get(
        'showPendoDescriptionButtonGuide',
      );

      if (showPendoDescriptionButtonGuide === 'true') {
        setURLParamsForPendoGuide('showPendoDescriptionButtonGuide');
      }

      if (showPendoRequestMoreButtonGuide === 'true') {
        setURLParamsForPendoGuide('showPendoRequestMoreButtonGuide');
      }
    }
  }, [userPrivilegesLoading, isGPTEnabled]);

  // watch URL for pendo key and show guide when present
  useEffect(() => {
    if (urlParamsForPendoGuide && isGPTEnabled) {
      initializePendo({
        user,
        userLicense,
        isCookieAccepted: true,
      });
      if (urlParamsForPendoGuide === 'showPendoDescriptionButtonGuide') {
        window?.pendo?.showGuideByName(pendoDescriptionGuideName);
      }

      if (urlParamsForPendoGuide === 'showPendoRequestMoreButtonGuide') {
        window?.pendo?.showGuideByName(pendoRequestMoreGuideName);
      }

      const url = new URL(window.location.href);
      url.searchParams.delete('showPendoRequestMoreButtonGuide');
      url.searchParams.delete('showPendoDescriptionButtonGuide');
      navigate(url.pathname.toString() + url.search.toString());
    }
  }, [
    urlParamsForPendoGuide,
    user,
    userLicense,
    isGPTEnabled,
    userPrivilegesLoading,
    navigate,
  ]);

  const isMissingRule = loadingDescriptionError?.message?.includes(
    'Required "rule" data is missing',
  );

  const showCorelightDescription =
    !descriptionText && corelightDescription && !isLoadingDescription;

  const showRequestDescription =
    !descriptionText &&
    !isLoadingDescription &&
    !isDescriptionQuickView &&
    (!loadingDescriptionError || isMissingRule) &&
    !showCorelightDescription;

  const showDescriptionError =
    loadingDescriptionError &&
    !isMissingRule &&
    !showRequestDescription &&
    !showCorelightDescription;

  const shouldShowGPTToolTipIcon =
    !isLoadingDescription && (descriptionText || !isDescriptionPanel);

  const GPTPromptContents = () => {
    return (
      <>
        {/* GPT is unset */}
        {isGPTEnabled === null &&
          (userPrivilegesLoading || isLoadingDescription) && <LoadingSpinner />}

        {/* GPT is disabled */}
        {isGPTEnabled === false && showGPTDisabled && !isLoadingDescription && (
          <VStack alignItems="start" justifyContent="center">
            <Text
              data-testid="chat-gpt-is-disabled-privileges"
              textStyle="body-md"
            >
              {userRole === 'admin'
                ? i18n.formatString(
                    gptOffAdminMessage,
                    isDescriptionPanel ? description : questionPrompt,
                  )
                : gptOffAnalystMessage}
            </Text>
            {userRole === 'admin' && (
              <Button
                data-testid="chat-gpt-is-disabled-settings-button"
                onClick={() => navigate(systemSettingsIntegrationsConfig)}
                variant="solid"
                colorScheme="gray"
              >
                {settings}
              </Button>
            )}
          </VStack>
        )}

        {/* user has not accepted user analytics modal */}
        {isGPTEnabled && !isDescriptionQuickView && !isLoadingDescription && (
          <EnableCookiesModal
            isOpen={isEnableCookiesModalOpen}
            onClose={onCloseAnalyticsModal}
            updateURLParams={updateURLParams}
            setPendoGuideType={setPendoGuideType}
            pendoGuideType={pendoGuideType}
          />
        )}

        {/* description area has text */}
        {isGPTEnabled &&
          descriptionText &&
          !isLoadingDescription &&
          !loadingDescriptionError && (
            <Panel layerStyle="first">
              <VStack alignItems="start">
                <ChatGPTQuestionPrompt text={generatedFromAI} />
                <MarkdownRenderer
                  data-testid="chat-gpt-cloud-prompt-panel-description"
                  components={{
                    h1: ({ children }) => (
                      <Heading
                        as="h2"
                        textStyle="h4"
                        children={children}
                        style={{ fontSize: 'large' }}
                      />
                    ),
                    h2: ({ children }) => (
                      <Heading
                        as="h3"
                        textStyle="h4"
                        children={children}
                        style={{ fontSize: 'large' }}
                      />
                    ),
                    h3: ({ children }) => (
                      <Heading
                        as="h3"
                        textStyle="h4"
                        children={children}
                        style={{ fontSize: 'large' }}
                      />
                    ),
                  }}
                  children={descriptionText}
                />
              </VStack>
            </Panel>
          )}

        {/* description area no text -- request description */}
        {isGPTEnabled && showRequestDescription && (
          <Panel layerStyle="first">
            <VStack alignItems="start">
              <ChatGPTQuestionPrompt text={generatedFromAI} />
              <Text
                data-testid="chat-gpt-request-description-no-description-text"
                textStyle="body-md"
              >
                {noDescription}
              </Text>
              <Button
                data-testid="chat-gpt-request-description-no-description-button"
                onClick={() => handleShowPendoGuideByName('description')}
                variant="solid"
                colorScheme="gray"
              >
                {requestDescription}
              </Button>
            </VStack>
          </Panel>
        )}

        {/* description area loading */}
        {isGPTEnabled !== null && isLoadingDescription && (
          <>
            {(descriptionText || [])?.length < 1 &&
              (isDescriptionQuickView ? (
                <GraphQLReqStatus
                  data-testid="chat-gpt-cloud-prompt-panel-description-loading"
                  loading={Boolean(isLoadingDescription)}
                />
              ) : (
                <Box w="100%">
                  <Text color="text.300" textStyle="body-md">
                    {loadingDescription}
                  </Text>
                  <SkeletonLoader rowCount={5} />
                </Box>
              ))}
          </>
        )}

        {/* description area error */}
        {isGPTEnabled && showDescriptionError && (
          <GraphQLReqStatus error={loadingDescriptionError} loading={false} />
        )}

        {/* corelight description text */}
        {showCorelightDescription && (
          <VStack data-testid="detection-description" alignItems="start">
            <Box>
              <Text
                textStyle="body-md"
                color="text.primary"
                data-testid="corelight-detections-description"
              >
                {corelightDescription}
              </Text>
            </Box>
          </VStack>
        )}
      </>
    );
  };

  return isAccordion ? (
    <GammaAccordionPanel
      w="100%"
      layerStyle="first"
      reduceMotion={true}
      storageKey={storageKey}
      disableBorders={disableBorders}
      title={
        <HStack>
          {shouldShowGPTToolTipIcon && <ChatGPTToolTip />}
          <Text>{titleText}</Text>
        </HStack>
      }
    >
      <GPTPromptContents />
    </GammaAccordionPanel>
  ) : (
    <GPTPromptContents />
  );
};
