import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  PlacementWithLogical,
  Tooltip,
} from '@chakra-ui/react';
import { ReactNode, forwardRef } from 'react';

export interface IconButtonProps extends ChakraIconButtonProps {
  onTooltipOpen?: () => void;
  onTooltipClose?: () => void;
  tooltipOpenDelay?: number;
  tooltipCloseDelay?: number;
  tooltipPlacement?: PlacementWithLogical;
  tooltipHasArrow?: boolean;
  tooltip?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      onTooltipClose,
      onTooltipOpen,
      tooltipCloseDelay,
      tooltipOpenDelay,
      tooltipPlacement,
      tooltipHasArrow = true,
      tooltip,
      size,
      ...buttonProps
    },
    ref,
  ) => {
    return (
      <Tooltip
        onClose={onTooltipClose}
        onOpen={onTooltipOpen}
        closeDelay={tooltipCloseDelay}
        openDelay={tooltipOpenDelay}
        placement={tooltipPlacement}
        isDisabled={tooltip === undefined}
        label={tooltip}
        shouldWrapChildren={buttonProps.isDisabled}
        hasArrow={tooltipHasArrow}
      >
        <ChakraIconButton size={`box-${size}`} {...buttonProps} ref={ref} />
      </Tooltip>
    );
  },
);
