import { Box, Flex, Link, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { IconButton } from '@gamma/buttons';
import { ChevronLeftIcon, ChevronRightIcon } from '@gamma/icons';
import { i18n } from '@gamma/investigator/localization';
import { useSimpleSearchQuery } from '@gamma/investigator/queries';
import { AccordionPanel } from '@gamma/layout';
import { GraphQLReqStatus } from '@gamma/progress';
import {
  DetailsGrid,
  DetailsGridRow,
} from 'libs/investigator/pages/detections/src/lib/Components';
import moment from 'moment';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LogscaleDetailsModal } from '../LogscaleDetailsModal';
import { ConnHistorySummary } from './ConnHistorySummary';

const { connection, dns, http, ssl, file, seeMore } =
  i18n.pages.detections.logscaleDetails;

interface LogscaleDetailsProps {
  alertsUid: string;
  alertsName: string;
  alertsTimestamp: string;
  alertsTenant?: string;
  setDownloadUrl?: Dispatch<SetStateAction<string>>;
}

interface connectionDetailsParsedData {
  history: string;
  orig_bytes: string;
  local_orig: string;
  local_resp: string;
  resp_bytes: string;
  service: string;
  _write_ts: string;
  'id.resp_p': string;
  'spcap.url': string;
}

interface httpDetailsParsedData {
  method: string;
  uri: string;
  user_agent: string;
  post_body: string;
}

interface sslDetailsParsedData {
  subject: string;
  '@rawstring': string;
  server_name: string;
  issuer: string;
  validation_status: string;
}

interface fileDetailsParsedData {
  mime_type: string;
  'rx_hosts[0]': string;
  seen_bytes: string;
}

interface dnsDetailsParsedData {
  '@rawstring': string;
  answers: string[];
  qtype_name: string;
  rcode_name: string;
}

interface MultiEventsSelectProps {
  currentPage: number;
  pageLength: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const MultiEventsSelect = ({
  currentPage,
  pageLength,
  setCurrentPage,
}: MultiEventsSelectProps) => {
  const handleNext = () => {
    if (currentPage < pageLength - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    pageLength > 1 && (
      <Flex justify="space-between" align="center" mb={4}>
        <IconButton
          icon={<ChevronLeftIcon />}
          variant="ghost"
          color="gray"
          onClick={handlePrevious}
          isDisabled={currentPage === 0}
          aria-label="Previous"
        />
        <Text fontSize="lg">
          {currentPage + 1}/{pageLength}
        </Text>
        <IconButton
          icon={<ChevronRightIcon />}
          variant="ghost"
          color="gray"
          onClick={handleNext}
          isDisabled={currentPage === pageLength - 1}
          aria-label="Next"
        />
      </Flex>
    )
  );
};

export const LogscaleDetails = ({
  alertsUid,
  alertsName,
  alertsTimestamp,
  alertsTenant,
  setDownloadUrl,
}: LogscaleDetailsProps) => {
  const [connectionDetailsParsedData, setConnectionDetailsParsedData] =
    useState<connectionDetailsParsedData>();
  const [httpDetailsParsedData, setHttpDetailsParsedData] = useState<
    httpDetailsParsedData[]
  >([]);
  const [sslDetailsParsedData, setSslDetailsParsedData] =
    useState<sslDetailsParsedData>();
  const [fileDetailsParsedData, setFileDetailsParsedData] = useState<
    fileDetailsParsedData[]
  >([]);
  const [dnsDetailsParsedData, setDnsDetailsParsedData] = useState<
    dnsDetailsParsedData[]
  >([]);
  const [moreDetailsParsedData, setMoreDetailsParsedData] = useState<any>();
  const [logscaleDetailTitle, setLogscaleDetailTitle] = useState<string>();
  const [sslCertChainFPS, setSslCertChainFPS] = useState<string>('-');
  const [dnsCurrentPage, setDnsCurrentPage] = useState<number>(0);
  const [httpCurrentPage, setHttpCurrentPage] = useState<number>(0);
  const [filesCurrentPage, setFilesCurrentPage] = useState<number>(0);
  const [queryVariables, setQueryVariables] = useState({
    pathName: '',
    currentIndex: 0,
  });

  const {
    isOpen: showMoreConnectionIsOpen,
    onOpen: showMoreConnectionOnOpen,
    onClose: showMoreConnectionOnClose,
  } = useDisclosure();

  const startTime = moment(alertsTimestamp).subtract(1, 'hours').unix();

  const endTime = moment(alertsTimestamp).add(24, 'hours').unix();

  const { loading: connectionDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=conn|uid="${alertsUid}"|groupby([_write_ts], function=collect([history, id.resp_p, service, orig_bytes, resp_bytes, local_orig, local_resp, spcap.url]))`,
      start: startTime.toString(),
      end: endTime.toString(),
      tenantsForQuery: [alertsTenant],
    },
    onCompleted: (data) => {
      if (data?.simpleSearchQuery?.events[0]) {
        const connectionData = JSON.parse(data?.simpleSearchQuery?.events[0]);
        setConnectionDetailsParsedData(connectionData);
        setDownloadUrl &&
          setDownloadUrl(
            connectionData?.['spcap.url'] ? connectionData?.['spcap.url'] : '',
          );
      }
    },
  });

  const { loading: dnsDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=dns|uid="${alertsUid}"|sort(_write_ts, order=asc, limit=200)`,
      start: startTime.toString(),
      end: endTime.toString(),
      tenantsForQuery: [alertsTenant],
    },
    onCompleted: (data) => {
      if (data?.simpleSearchQuery?.events[0]) {
        const dnsDetailsResults: dnsDetailsParsedData[] = [];
        data.simpleSearchQuery.events.map((eventData) => {
          const dnsDetailsResult = JSON.parse(
            eventData,
          ) as dnsDetailsParsedData;
          const rawString = JSON.parse(dnsDetailsResult['@rawstring']);
          const answers = rawString?.answers as string[];
          dnsDetailsResult.answers = answers;
          dnsDetailsResults.push(dnsDetailsResult);
        });
        setDnsDetailsParsedData(dnsDetailsResults);
      }
    },
  });

  const { loading: httpDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=http|uid="${alertsUid}"|groupby([_write_ts], function=collect([method, uri, user_agent, post_body, status_code, client_header[0], client_header[1], client_header[2], host]))`,
      start: startTime.toString(),
      end: endTime.toString(),
      tenantsForQuery: [alertsTenant],
    },
    onCompleted: (data) => {
      if (data?.simpleSearchQuery?.events[0]) {
        const httpDetailsResults: httpDetailsParsedData[] = [];
        data.simpleSearchQuery.events.map((eventData) => {
          const httpDetailsResult = JSON.parse(
            eventData,
          ) as httpDetailsParsedData;
          httpDetailsResults.push(httpDetailsResult);
        });
        setHttpDetailsParsedData(httpDetailsResults);
      }
    },
  });

  const { loading: sslDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=ssl|uid="${alertsUid}"|groupby([_write_ts], function=collect([subject, @rawstring, server_name, issuer, validation_status]))`,
      start: startTime.toString(),
      end: endTime.toString(),
      tenantsForQuery: [alertsTenant],
    },
    onCompleted: (data) => {
      const sslDetailsResult: sslDetailsParsedData | undefined = data
        ?.simpleSearchQuery?.events[0]
        ? (JSON.parse(
            data?.simpleSearchQuery?.events[0],
          ) as sslDetailsParsedData)
        : undefined;
      setSslDetailsParsedData(sslDetailsResult);
      if (sslDetailsResult && sslDetailsResult['@rawstring']) {
        const rawString = JSON.parse(sslDetailsResult['@rawstring']);
        const certChainFps = rawString?.cert_chain_fps as string[];
        setSslCertChainFPS(JSON.stringify(certChainFps));
      }
    },
  });

  const { loading: fileDetailsLoading } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=files|uid="${alertsUid}"|groupby([_write_ts], function=collect([fuid, md5, sha256, mime_type, seen_bytes, rx_hosts[0], rx_hosts[1], rx_hosts[2]]))`,
      start: startTime.toString(),
      end: endTime.toString(),
      tenantsForQuery: [alertsTenant],
    },
    onCompleted: (data) => {
      if (data?.simpleSearchQuery?.events[0]) {
        const fileDetailsResults: fileDetailsParsedData[] = [];
        data.simpleSearchQuery.events.map((eventData) => {
          const fileDetailsResult = JSON.parse(
            eventData,
          ) as fileDetailsParsedData;
          fileDetailsResults.push(fileDetailsResult);
        });
        setFileDetailsParsedData(fileDetailsResults);
      }
    },
  });

  const { loading: moreConnectionDetailsLoading, data } = useSimpleSearchQuery({
    variables: {
      clientSessionId: localStorage.getItem('session_id_token'),
      queryString: `#path=${queryVariables.pathName}|uid="${alertsUid}"|sort(_write_ts, order=asc, limit=200)`,
      start: startTime.toString(),
      end: endTime.toString(),
      tenantsForQuery: [alertsTenant],
    },
    skip: !queryVariables.pathName,
  });

  const handleSearchQuery = (pathName: string, currentIndex: number) => {
    setQueryVariables({ pathName, currentIndex });
  };

  useEffect(() => {
    if (data && data?.simpleSearchQuery?.events[0]) {
      setMoreDetailsParsedData(
        JSON.parse(data.simpleSearchQuery.events[queryVariables.currentIndex]),
      );
    }
  }, [data, queryVariables]);

  return (
    <VStack w="100%" alignItems="start">
      <Box w="100%">
        <AccordionPanel title={connection.title}>
          {connectionDetailsLoading ? (
            <GraphQLReqStatus loading={connectionDetailsLoading} />
          ) : (
            <DetailsGrid>
              <DetailsGridRow
                title={connection.history}
                data-testid="detections-history"
              >
                {connectionDetailsParsedData?.history ? (
                  <ConnHistorySummary
                    history={connectionDetailsParsedData.history}
                  />
                ) : (
                  <Text overflowWrap="anywhere">-</Text>
                )}
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.protocol}
                data-testid="detections-protocol"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.service
                    ? connectionDetailsParsedData.service
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.port}
                data-testid="detections-port"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.['id.resp_p']
                    ? connectionDetailsParsedData['id.resp_p']
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.bytesSent}
                data-testid="detections-bytes-sent"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.orig_bytes
                    ? connectionDetailsParsedData.orig_bytes
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.bytesReturned}
                data-testid="detections-bytes-returned"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.resp_bytes
                    ? connectionDetailsParsedData.resp_bytes
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.localOriginator}
                data-testid="detections-local-originator"
              >
                <Text overflowWrap="anywhere">
                  {connectionDetailsParsedData?.local_orig
                    ? connectionDetailsParsedData.local_orig
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={connection.localResponder}
                data-testid="detections-local-responder"
              >
                <Text>
                  {connectionDetailsParsedData?.local_resp
                    ? connectionDetailsParsedData.local_resp
                    : '-'}
                </Text>
              </DetailsGridRow>
              {connectionDetailsParsedData && (
                <Link
                  color="text.link"
                  data-testid="see-more-button"
                  onClick={() => {
                    handleSearchQuery('conn', 0);
                    setLogscaleDetailTitle(connection.title);
                    showMoreConnectionOnOpen();
                  }}
                >
                  {seeMore}
                </Link>
              )}
            </DetailsGrid>
          )}
        </AccordionPanel>
      </Box>

      <Box w="100%">
        <AccordionPanel title={dns.title}>
          {dnsDetailsLoading ? (
            <GraphQLReqStatus loading={dnsDetailsLoading} />
          ) : (
            <>
              <MultiEventsSelect
                currentPage={dnsCurrentPage}
                pageLength={dnsDetailsParsedData.length}
                setCurrentPage={setDnsCurrentPage}
              />
              <DetailsGrid>
                <DetailsGridRow title={dns.answers} data-testid="dns-answers">
                  <Text overflowWrap="anywhere">
                    {dnsDetailsParsedData[dnsCurrentPage]?.answers
                      ? dnsDetailsParsedData[dnsCurrentPage].answers
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={dns.qtypeName}
                  data-testid="dns-qtype-name"
                >
                  <Text overflowWrap="anywhere">
                    {dnsDetailsParsedData[dnsCurrentPage]?.qtype_name
                      ? dnsDetailsParsedData[dnsCurrentPage].qtype_name
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={dns.rcodeName}
                  data-testid="dns-rcode-name"
                >
                  <Text overflowWrap="anywhere">
                    {dnsDetailsParsedData[dnsCurrentPage]?.rcode_name
                      ? dnsDetailsParsedData[dnsCurrentPage].rcode_name
                      : '-'}
                  </Text>
                </DetailsGridRow>
                {dnsDetailsParsedData.length > 0 && (
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('dns', dnsCurrentPage);
                      setLogscaleDetailTitle(dns.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                )}
              </DetailsGrid>
            </>
          )}
        </AccordionPanel>
      </Box>

      <Box w="100%">
        <AccordionPanel title={http.title}>
          {httpDetailsLoading ? (
            <GraphQLReqStatus loading={httpDetailsLoading} />
          ) : (
            <>
              <MultiEventsSelect
                currentPage={httpCurrentPage}
                pageLength={httpDetailsParsedData.length}
                setCurrentPage={setHttpCurrentPage}
              />
              <DetailsGrid>
                <DetailsGridRow title={http.uri} data-testid="detections-uri">
                  <Text overflowWrap="anywhere">
                    {httpDetailsParsedData[httpCurrentPage]?.uri
                      ? httpDetailsParsedData[httpCurrentPage]?.uri
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={http.method}
                  data-testid="detections-method"
                >
                  <Text overflowWrap="anywhere">
                    {httpDetailsParsedData[httpCurrentPage]?.method
                      ? httpDetailsParsedData[httpCurrentPage]?.method
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={http.postBody}
                  data-testid="detections-post-body"
                >
                  <Text overflowWrap="anywhere">
                    {httpDetailsParsedData[httpCurrentPage]?.post_body
                      ? httpDetailsParsedData[httpCurrentPage]?.post_body
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={http.userAgent}
                  data-testid="detections-user-agent"
                >
                  <Text overflowWrap="anywhere">
                    {httpDetailsParsedData[httpCurrentPage]?.user_agent
                      ? httpDetailsParsedData[httpCurrentPage]?.user_agent
                      : '-'}
                  </Text>
                </DetailsGridRow>
                {httpDetailsParsedData.length > 0 && (
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('http', httpCurrentPage);
                      setLogscaleDetailTitle(http.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                )}
              </DetailsGrid>
            </>
          )}
        </AccordionPanel>
      </Box>
      <Box w="100%">
        <AccordionPanel title={ssl.title}>
          {sslDetailsLoading ? (
            <GraphQLReqStatus loading={sslDetailsLoading} />
          ) : (
            <DetailsGrid>
              <DetailsGridRow title="Subject" data-testid="detections-subject">
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.subject
                    ? sslDetailsParsedData?.subject
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.certChainFps}
                data-testid="detections-cert-chain-fps"
              >
                <Text overflowWrap="anywhere">{sslCertChainFPS}</Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.serverName}
                data-testid="detections-server-name"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.server_name
                    ? sslDetailsParsedData?.server_name
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.issuer}
                data-testid="detections-issuer"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.issuer
                    ? sslDetailsParsedData?.issuer
                    : '-'}
                </Text>
              </DetailsGridRow>
              <DetailsGridRow
                title={ssl.validationStatus}
                data-testid="detections-validation-status"
              >
                <Text overflowWrap="anywhere">
                  {sslDetailsParsedData?.validation_status
                    ? sslDetailsParsedData?.validation_status
                    : '-'}
                </Text>
              </DetailsGridRow>
              {sslDetailsParsedData && (
                <Link
                  color="text.link"
                  data-testid="see-more-button"
                  onClick={() => {
                    handleSearchQuery('ssl', 0);
                    setLogscaleDetailTitle(ssl.title);
                    showMoreConnectionOnOpen();
                  }}
                >
                  {seeMore}
                </Link>
              )}
            </DetailsGrid>
          )}
        </AccordionPanel>
      </Box>
      <Box w="100%">
        <AccordionPanel title={file.title}>
          {fileDetailsLoading ? (
            <GraphQLReqStatus loading={fileDetailsLoading} />
          ) : (
            <>
              <MultiEventsSelect
                currentPage={filesCurrentPage}
                pageLength={fileDetailsParsedData.length}
                setCurrentPage={setFilesCurrentPage}
              />
              <DetailsGrid>
                <DetailsGridRow
                  title={file.mimeType}
                  data-testid="detections-mime-type"
                >
                  <Text overflowWrap="anywhere">
                    {fileDetailsParsedData[filesCurrentPage]?.mime_type
                      ? fileDetailsParsedData[filesCurrentPage]?.mime_type
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={file.rxHosts}
                  data-testid="detections-rx-hosts"
                >
                  <Text overflowWrap="anywhere">
                    {fileDetailsParsedData[filesCurrentPage]?.['rx_hosts[0]']
                      ? fileDetailsParsedData[filesCurrentPage]?.['rx_hosts[0]']
                      : '-'}
                  </Text>
                </DetailsGridRow>
                <DetailsGridRow
                  title={file.seenBytes}
                  data-testid="detections-seen-bytes"
                >
                  <Text overflowWrap="anywhere">
                    {fileDetailsParsedData[filesCurrentPage]?.seen_bytes
                      ? fileDetailsParsedData[filesCurrentPage]?.seen_bytes
                      : '-'}
                  </Text>
                </DetailsGridRow>
                {fileDetailsParsedData.length > 0 && (
                  <Link
                    color="text.link"
                    data-testid="see-more-button"
                    onClick={() => {
                      handleSearchQuery('files', filesCurrentPage);
                      setLogscaleDetailTitle(file.title);
                      showMoreConnectionOnOpen();
                    }}
                  >
                    {seeMore}
                  </Link>
                )}
              </DetailsGrid>
            </>
          )}
        </AccordionPanel>
      </Box>
      <LogscaleDetailsModal
        alertsName={alertsName}
        alertsTimestamp={alertsTimestamp}
        isModalOpen={showMoreConnectionIsOpen}
        onModalClose={showMoreConnectionOnClose}
        data={moreDetailsParsedData}
        loading={moreConnectionDetailsLoading}
        title={logscaleDetailTitle || ''}
      />
    </VStack>
  );
};
