import { chakra, HStack, usePrevious } from '@chakra-ui/react';
import { padNum } from '@gamma/util';
import { debounce, isEqual } from 'lodash';
import moment from 'moment';
import {
  FocusEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  DateNumberInput,
  DateTimeInputWrapper,
  DateTimeInputWrapperProps,
} from '../Components';
import {
  getDateTimeNumberValue,
  handleDateTimeInputKeydown,
  InputFocusOrderRefs,
} from '../util';

type DateInputType = 'month' | 'day' | 'year';
export interface DateInputProps
  extends Omit<DateTimeInputWrapperProps, 'children'> {
  value?: Date;
  onChange?: (date: Date) => void;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
  minDate?: Date;
  maxDate?: Date;
}

export const DateInput = ({
  value: propsValue,
  minDate = new Date('January 1, 2000'), // keeps onChange from triggering when an inputted year is < 1000
  maxDate,
  leftElement,
  rightElement,
  error: propsError = false,
  onChange,
  onFocus,
  onBlur,
  onKeyDown,
  'data-testid': dataTestId = 'date-input',
  ...props
}: DateInputProps) => {
  const [value, setValue] = useState<{
    month: string;
    day: string;
    year: string;
  }>({
    month: propsValue?.getMonth().toString() ?? '',
    day: propsValue?.getDay().toString() ?? '',
    year: propsValue?.getFullYear().toString() ?? '',
  });
  const previousValue = usePrevious(value);
  const previousPropsValue = usePrevious(propsValue);
  const [error, setError] = useState<string | boolean>(propsError);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [hasFocus, setHasFocus] = useState<boolean>(false);

  // force minDate to be start of the day
  const sanitizedMinDate = moment(minDate).hour(0).minute(0).second(0);
  // force maxDate to be end of the day
  const sanitizedMaxDate = maxDate
    ? moment(maxDate).hour(23).minute(59).second(59)
    : undefined;

  const monthRef = useRef<HTMLInputElement>(null);
  const dayRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setError(propsError || error);
  }, [propsError, error]);

  const validateDate = useCallback(
    (month: string, day: string, year: string) => {
      if (!isDirty && !propsValue) {
        return false;
      }
      const date = moment({
        M: parseInt(month, 10) - 1,
        d: parseInt(day, 10),
        y: parseInt(year, 10),
      });
      const localDateFormat = moment.localeData().longDateFormat('L');

      let error: string | boolean = '';
      switch (true) {
        case Boolean(propsError):
          error = propsError;
          break;
        case !month:
        case !day:
        case !year:
        case !date.isValid():
          error = 'Invalid Date';
          break;
        case sanitizedMinDate &&
          moment(date)
            .hour(23)
            .minute(59)
            .second(59)
            .isBefore(sanitizedMinDate):
          // if the user selects the same day as min date it should work, but since
          // we create the date with 00:00:00 timestamp this check was erroring as
          // being before the minDate, so we force it to compare against the last
          // second of the day
          error = `Date cannot be before ${moment(sanitizedMinDate).format(
            localDateFormat,
          )}`;
          break;
        case sanitizedMaxDate && date.isAfter(sanitizedMaxDate):
          error = `Date cannot be after ${moment(sanitizedMaxDate).format(
            localDateFormat,
          )}`;
          break;
        default:
          error = '';
      }

      setError(error);
      return Boolean(error);
    },
    [isDirty, propsValue, propsError, minDate, maxDate],
  );

  useEffect(() => {
    if (propsValue !== previousPropsValue) {
      if (propsValue !== undefined) {
        const momentDate = moment(propsValue);
        const month = `${momentDate.month() + 1}`;
        const day = momentDate.date().toString();
        const year = momentDate.year().toString();
        validateDate(month, day, year);
        setValue({ month, day, year });
      } else {
        setValue({
          month: '',
          day: '',
          year: '',
        });
      }
    }
  }, [propsValue, previousPropsValue, validateDate]);

  // whenever the 3 inputs are changed, validate the overall value
  useEffect(() => {
    const isDateSame = isEqual(value, previousValue);
    const dayOrMonthNotComplete = [value.day, value.month].includes('0');
    const yearNotComplete = value.year.length < 4;

    /**
     * isDateSame keeps input from getting into infinite loop of updates.
     * dayOrMonthNotComplete && yearNotComplete keeps validation and onChange from
     * triggering before user is done changing the value (mostly important for
     * the day as moment will set the day to "1" if you give it 0)
     */
    if (isDateSame || dayOrMonthNotComplete || yearNotComplete) {
      return;
    }
    const hasError = validateDate(value.month, value.day, value.year);

    // if everything is OK with the date, trigger onChange prop
    if (isDirty && !hasError) {
      const momentDate = moment({
        M: parseInt(value.month, 10) - 1,
        d: parseInt(value.day, 10),
        y: parseInt(value.year, 10),
      });
      onChange?.(momentDate.toDate());
    }
  }, [value, previousValue, isDirty, validateDate, onChange]);

  const refMap = useMemo(() => {
    const localDateFormat = moment.localeData().longDateFormat('L');
    const splitDateFormat = localDateFormat.split('/');
    const isMonthFirst = splitDateFormat[0].toLowerCase() === 'mm';

    return new Map<DateInputType, InputFocusOrderRefs>([
      [
        'month',
        {
          prev: isMonthFirst ? undefined : dayRef,
          curr: monthRef,
          next: isMonthFirst ? dayRef : yearRef,
        },
      ],
      [
        'day',
        {
          prev: isMonthFirst ? monthRef : undefined,
          curr: dayRef,
          next: isMonthFirst ? yearRef : monthRef,
        },
      ],
      ['year', { prev: isMonthFirst ? dayRef : monthRef, curr: yearRef }],
    ]);
  }, []);

  const handleInputChange =
    (currVal: string, type: DateInputType) =>
    (string: string, number: number) => {
      const { next } = refMap.get(type) ?? {};
      setIsDirty(true);
      const { newValue, shouldBlur } = getDateTimeNumberValue(
        currVal,
        string,
        number,
      );
      setValue({
        ...value,
        [type]: type === 'year' ? string.substring(0, 4) : newValue,
      });
      // "year" input should not blur
      if (type !== 'year' && shouldBlur) {
        // debounce to prevent value change from clamping due to blur
        debounce(() => {
          next?.current?.focus();
          next?.current?.select();
        })();
      }
    };

  const inputs = useMemo(() => {
    const localDateFormat = moment.localeData().longDateFormat('L');
    const splitDateFormat = localDateFormat.split('/');
    const isMonthFirst = splitDateFormat[0].toLowerCase() === 'mm';
    const typeArray: DateInputType[] = isMonthFirst
      ? ['month', 'day', 'year']
      : ['day', 'month', 'year'];

    return (
      <HStack
        data-testid="date-input-stack"
        divider={<chakra.span borderLeftWidth="0px !important">/</chakra.span>}
        pl={leftElement ? 1 : 3} // slightly larger to visually center inputs
        pr={rightElement ? 0 : 2}
      >
        {typeArray.map((type) => {
          return (
            <DateNumberInput
              {...props}
              ref={refMap.get(type)?.curr}
              onChange={handleInputChange(value[type], type)}
              value={type === 'year' ? value[type] : padNum(value[type])}
              type={type}
              onKeyDown={handleDateTimeInputKeydown(
                refMap.get(type),
                onKeyDown,
              )}
              data-testid={`${dataTestId}-${type}`}
              key={type}
            />
          );
        })}
      </HStack>
    );
  }, [value]);

  // handle focus of date inputs so that onFocus prop doesn't get triggered
  // every single time one of the 3 inputs is focused
  const handleFocus = (event: FocusEvent<HTMLDivElement>) => {
    if (hasFocus) return;

    const targetIsInComponent = gridRef.current?.contains(event.currentTarget);

    if (targetIsInComponent) {
      setHasFocus(true);
      onFocus?.(event);
    }
  };

  // handle blur of date inputs so that onBlur prop doesn't get triggered
  // every single time one of the 3 inputs is blurred
  const handleBlur = useCallback(
    (event: FocusEvent<HTMLDivElement>) => {
      const targetIsInComponent = gridRef.current?.contains(
        event.relatedTarget,
      );
      if (!targetIsInComponent) {
        setHasFocus(false);
        validateDate(value.month, value.day, value.year);
        onBlur?.(event);
      }
    },
    [validateDate],
  );

  return (
    <DateTimeInputWrapper
      {...props}
      onBlur={handleBlur}
      onFocus={handleFocus}
      leftElement={leftElement}
      rightElement={rightElement}
      error={error}
      ref={gridRef}
      data-testid={dataTestId}
    >
      {inputs}
    </DateTimeInputWrapper>
  );
};
