import { Box } from '@chakra-ui/react';
import { InvestigatorLogo } from '@gamma/icons';
import {
  AlertsCatalogTable,
  PrivilegedAccessWrapper,
  Wrapper,
} from '@gamma/investigator/components';
import {
  ErrorCode,
  Features,
  ROUTES,
  URLS,
} from '@gamma/investigator/constants';
import {
  AuthContext,
  DateRangeContext,
  SlideDrawerContext,
} from '@gamma/investigator/context';
import { Account } from '@gamma/investigator/pages/account';
import {
  Access,
  Admin,
  UsersAccess,
  UsersAndAccess,
} from '@gamma/investigator/pages/admin';
import { AlertToDetection } from '@gamma/investigator/pages/alert-to-detection';
import {
  Detection,
  DetectionDetails,
  Detections,
} from '@gamma/investigator/pages/detections';
import { HumioDashboards } from '@gamma/investigator/pages/humio-dashboards';
import { HumioSearch } from '@gamma/investigator/pages/humio-search';
import {
  ForgotPassword,
  Login,
  Registration,
} from '@gamma/investigator/pages/login';
import {
  HumioDashboardOverview,
  Overview,
  SecurityOverview,
} from '@gamma/investigator/pages/overview';
import {
  AlertCatalogDetails,
  AlertCatalogOutlet,
  General,
  Integrations,
  IntegrationsAlertExports,
  IntegrationsConfig,
  LockedAlertExport,
  Onboard,
  SecurityAudit,
  SensorMonitoring,
  System,
} from '@gamma/investigator/pages/system';
import { SlideDrawer } from '@gamma/layout';
import { ErrorPage, SplitPage } from '@gamma/pages';
import { GraphQLReqStatus } from '@gamma/progress';
import { CognitoUserInterface } from '@gamma/shared/types';
import { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { ConditionalRedirects } from './ConditionalRedirects';

const { home, systemSettingsSensorMonitoring, notFound } = ROUTES;

export interface AppRoutesProps {
  isAdmin?: boolean;
  isSSOLogin: boolean;
  preferredMFA: string;
  user?: CognitoUserInterface;
  getAppConfigurationsLoading: boolean;
  getAppConfigurationsData: string | null;
  setPreferredMFA: Dispatch<SetStateAction<string>>;
}

export const AppRoutes = ({
  user,
  isAdmin,
  isSSOLogin,
  preferredMFA,
  setPreferredMFA,
  getAppConfigurationsData,
  getAppConfigurationsLoading,
}: AppRoutesProps) => {
  const { isOrgTenant } = useContext(AuthContext);

  const { setDateRange, defaultRangeIndex, setDefaultRangeIndex } =
    useContext(DateRangeContext);

  const [search, setSearch] = useState<string>('');
  const [authStateLoading, setAuthStateLoading] = useState<boolean>(true);
  const [tcAcceptanceLoading, setTcAcceptanceLoading] =
    useState<boolean>(false);

  const {
    slideDrawerTitle,
    isSlideDrawerOpen,
    slideDrawerOnClose,
    setSlideDrawerBody,
    setSlideDrawerActions,
    setIsSlideDrawerOpen,
    setSlideDrawerTitle,
  } = useContext(SlideDrawerContext);

  const router = useMemo(() => {
    return createBrowserRouter(
      createRoutesFromElements(
        <>
          {/* for all the routes without authentication */}
          <Route
            path="/"
            element={
              <>
                <SplitPage
                  links={[
                    {
                      icon: 'ContactIcon',
                      name: 'Contact Support',
                      url: URLS.CORELIGHT_SUPPORT,
                      isExternal: true,
                    },
                  ]}
                  logo={<InvestigatorLogo />}
                  left={<Outlet />}
                  right={
                    <Box
                      w="full"
                      h="full"
                      bgColor="black"
                      bg="url(/assets/images/CL_particle_wipe_black.jpg)"
                      bgPos="center"
                      bgSize="cover"
                    />
                  }
                />
              </>
            }
          >
            <Route path="sso-error" element={<Login.ssoLoginErrors />} />
          </Route>
          <Route
            path="/"
            element={
              <>
                <ConditionalRedirects
                  setSearch={setSearch}
                  isSSOLogin={isSSOLogin}
                  setDateRange={setDateRange}
                  defaultRangeIndex={defaultRangeIndex}
                  setDefaultRangeIndex={setDefaultRangeIndex}
                  setAuthStateLoading={setAuthStateLoading}
                  setTcAcceptanceLoading={setTcAcceptanceLoading}
                />
                {authStateLoading ? (
                  <GraphQLReqStatus loading={authStateLoading} />
                ) : (
                  <SplitPage
                    links={[
                      {
                        icon: 'ContactIcon',
                        name: 'Contact Support',
                        url: URLS.CORELIGHT_SUPPORT,
                        isExternal: true,
                      },
                    ]}
                    logo={<InvestigatorLogo />}
                    left={<Outlet />}
                    right={
                      <Box
                        w="full"
                        h="full"
                        bgColor="black"
                        bg="url(/assets/images/CL_particle_wipe_black.jpg)"
                        bgPos="center"
                        bgSize="cover"
                      />
                    }
                  />
                )}
              </>
            }
          >
            <Route
              path="privacy-policy"
              element={<Registration.ssoWelcome user={user} />}
            />
            <Route path="sso-error" element={<Login.ssoLoginErrors />} />
            <Route path="login">
              <Route
                index
                element={<Login.main setPreferredMFA={setPreferredMFA} />}
              />
              <Route path="mfa" element={<Login.mfa />} />
              <Route path="trouble">
                <Route index element={<Login.trouble />} />
                <Route path="password" element={<ForgotPassword.main />}>
                  <Route index element={<ForgotPassword.setUser />} />
                  <Route
                    path="reset"
                    element={<ForgotPassword.resetPassword />}
                  />
                  <Route
                    path="complete"
                    element={<ForgotPassword.complete />}
                  />
                </Route>
                <Route
                  path="two-factor-recovery"
                  element={<Login.twoFactorRecovery />}
                />
              </Route>
              <Route path="sso-login" element={<Login.ssoLogin />} />
            </Route>
            <Route path="registration" element={<Registration.main />}>
              <Route index element={<Registration.welcome />} />
              <Route
                path="setpassword"
                element={<Registration.setPassword />}
              />
              <Route path="mfa" element={<Registration.confirmOTP />} />
              <Route
                path="complete"
                element={<Registration.complete preferredMFA={preferredMFA} />}
              />
            </Route>
          </Route>
          <Route
            path="/"
            element={
              <>
                <ConditionalRedirects
                  setSearch={setSearch}
                  isSSOLogin={isSSOLogin}
                  setDateRange={setDateRange}
                  defaultRangeIndex={defaultRangeIndex}
                  setDefaultRangeIndex={setDefaultRangeIndex}
                  setAuthStateLoading={setAuthStateLoading}
                  setTcAcceptanceLoading={setTcAcceptanceLoading}
                />
                {authStateLoading ? (
                  <GraphQLReqStatus loading={authStateLoading} />
                ) : (
                  <Wrapper
                    user={user}
                    authStateLoading={authStateLoading}
                    getUserTcAcceptanceLoading={tcAcceptanceLoading}
                  >
                    <Outlet />
                    <SlideDrawer
                      isOpen={isSlideDrawerOpen}
                      title={
                        <div ref={(el) => setSlideDrawerTitle?.(el)}></div>
                      }
                      onClose={() => {
                        slideDrawerOnClose?.();
                        setIsSlideDrawerOpen?.(false);
                      }}
                      actions={
                        <div ref={(el) => setSlideDrawerActions?.(el)}></div>
                      }
                    >
                      <div ref={(el) => setSlideDrawerBody?.(el)}></div>
                    </SlideDrawer>
                  </Wrapper>
                )}
              </>
            }
          >
            <Route index element={<Onboard isAdmin={isAdmin} />} />
            <Route path="account">
              <Route path="settings" element={<Account />} />
            </Route>
            <Route path="admin" element={<Admin />}>
              <Route element={<UsersAndAccess />}>
                <Route path="users" element={<UsersAccess />} />
                <Route path="access" element={<Access />} />
              </Route>
            </Route>
            <Route path="system">
              <Route path="settings" element={<System />}>
                <Route
                  index
                  element={<Navigate to={systemSettingsSensorMonitoring} />}
                />
                <Route
                  path="integrations"
                  element={
                    !isOrgTenant ? <Integrations /> : <Navigate to={notFound} />
                  }
                >
                  <Route
                    path="configuration"
                    element={
                      !isOrgTenant ? (
                        <IntegrationsConfig
                          getAppConfigurationsLoading={
                            getAppConfigurationsLoading
                          }
                          getAppConfigurationsData={getAppConfigurationsData}
                        />
                      ) : (
                        <Navigate to={notFound} />
                      )
                    }
                  />
                  <Route
                    path="alert-exports"
                    element={
                      !isOrgTenant ? (
                        <IntegrationsAlertExports />
                      ) : (
                        <Navigate to={notFound} />
                      )
                    }
                  >
                    <Route
                      path=":exporter_id"
                      element={
                        isAdmin && !isOrgTenant ? (
                          <PrivilegedAccessWrapper
                            feature={Features.alertExport}
                            component={<IntegrationsAlertExports />}
                            lockedComponent={<LockedAlertExport />}
                          />
                        ) : (
                          <Navigate to={notFound} />
                        )
                      }
                    />
                  </Route>
                </Route>

                <Route
                  path="sensor-monitoring"
                  element={<SensorMonitoring />}
                />

                <Route
                  path="general"
                  element={isAdmin ? <General /> : <Navigate to={notFound} />}
                />

                <Route path="alert-catalog" element={<AlertCatalogOutlet />}>
                  <Route index element={<AlertsCatalogTable />}></Route>
                  <Route path=":content_id" element={<AlertCatalogDetails />} />
                </Route>
                <Route
                  path="audit-logs"
                  element={
                    isAdmin ? <SecurityAudit /> : <Navigate to={notFound} />
                  }
                />
              </Route>
            </Route>
            <>
              <Route path="overview" element={<Overview />}>
                <Route index element={<Navigate to={home} />} />
                <Route
                  path="security-overview"
                  element={<SecurityOverview />}
                />
                <Route
                  path="network-overview"
                  element={<HumioDashboardOverview />}
                />
                <Route
                  path="security-posture"
                  element={<HumioDashboardOverview />}
                />
                <Route path="more-dashboards/*" element={<HumioDashboards />} />
              </Route>
              <Route path="log-search" element={<HumioSearch />} />
              <Route path="detections">
                <Route
                  index
                  element={<Detections getAppConfigurationsLoading={false} />}
                />
                <Route path=":detection_id">
                  <Route
                    element={
                      <Detection
                        getAppConfigurationsData={getAppConfigurationsData}
                      />
                    }
                  >
                    <Route
                      index
                      element={<Navigate to={`details${search}`} />}
                    />
                    <Route
                      path="details"
                      element={
                        <DetectionDetails
                          getAppConfigurationsLoading={
                            getAppConfigurationsLoading
                          }
                          getAppConfigurationsData={getAppConfigurationsData}
                        />
                      }
                    />
                  </Route>
                </Route>
              </Route>

              <Route path="alert-to-detection">
                <Route index element={<AlertToDetection />} />
                <Route path=":alert_id" element={<AlertToDetection />} />
              </Route>
            </>
            <Route
              path="404"
              element={<ErrorPage code={ErrorCode.NOT_FOUND} />}
            />
            <Route
              path="500"
              element={<ErrorPage code={ErrorCode.SERVER_ERROR} />}
            />
          </Route>
          <Route path="*" element={<Navigate to={notFound} />} />
        </>,
      ),
    );
  }, [
    search,
    authStateLoading,
    slideDrawerTitle,
    isSlideDrawerOpen,
    tcAcceptanceLoading,
    user,
    isAdmin,
    isSSOLogin,
    isOrgTenant,
    preferredMFA,
    setPreferredMFA,
    setDateRange,
    defaultRangeIndex,
    setDefaultRangeIndex,
    getAppConfigurationsData,
    getAppConfigurationsLoading,
  ]);

  return <RouterProvider router={router} />;
};
