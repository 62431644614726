import { createContext, Dispatch, SetStateAction } from 'react';

export interface ISlideDrawerContext {
  portalType?: 'alert' | 'entity' | 'detection';
  setPortalType?: Dispatch<
    SetStateAction<'alert' | 'entity' | 'detection' | undefined>
  >;
  isSlideDrawerOpen?: boolean;
  setIsSlideDrawerOpen?: Dispatch<SetStateAction<boolean | undefined>>;
  slideDrawerTitle?: Element | null;
  setSlideDrawerTitle?: Dispatch<SetStateAction<Element | null>>;
  slideDrawerBody: Element | null;
  setSlideDrawerBody?: Dispatch<SetStateAction<Element | null>>;
  slideDrawerActions: Element | null;
  setSlideDrawerActions?: Dispatch<SetStateAction<Element | null>>;
  slideDrawerOnClose?: any;
  setSlideDrawerOnClose?: any;
}

export const SlideDrawerContext = createContext<ISlideDrawerContext>({
  portalType: undefined,
  setPortalType: () => false,
  slideDrawerTitle: undefined,
  setSlideDrawerTitle: () => false,
  isSlideDrawerOpen: undefined,
  setIsSlideDrawerOpen: () => false,
  slideDrawerBody: null,
  setSlideDrawerBody: () => false,
  slideDrawerActions: null,
  setSlideDrawerActions: () => false,
  slideDrawerOnClose: () => false,
  setSlideDrawerOnClose: () => false,
});
