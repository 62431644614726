import { CONSTANTS } from '@gamma/investigator/constants';
import {
  AuthContext,
  DateRangeProvider,
  LogScaleContext,
} from '@gamma/investigator/context';
import { useLogOperationCall } from '@gamma/investigator/hooks';
import {
  useAcceptEulaTC,
  useAcceptHumioTC,
  useGetAppConfigurations,
  useGetLicenseInfo,
  useGetPrivileges,
  useLazyGetPrivileges,
} from '@gamma/investigator/queries';
import { setHumioSessionId } from '@gamma/investigator/utilities';
import { useContext, useEffect, useState } from 'react';

import { AppRoutes } from './AppRoutes';

const { FEDERATION_TYPE, TENANT_TYPE } = CONSTANTS;

export const App = () => {
  const {
    user,
    userRole,
    setUserAlias,
    setIsOrgTenant,
    isPOVTenant,
    setIsPOVTenant,
    setUserPrivileges,
    setUserPrivilegesLoading,
    userLicense,
    setUserLicense,
    userLicenseLoading,
    setUserLicenseLoading,
  } = useContext(AuthContext);

  const { setSessionIdTokenCorelight } = useContext(LogScaleContext);

  const logOperationCall = useLogOperationCall();
  const [getAppConfigurations] = useGetAppConfigurations();
  const [getAppConfigurationsData, setGetAppConfigurationsData] = useState<
    string | null
  >(null);
  const [getAppConfigurationsLoading, setGetAppConfigurationsLoading] =
    useState<boolean>(true);

  const [preferredMFA, setPreferredMFA] = useState<string>('');

  const [getUserLicense, { loading: getUserLicenseLoading }] =
    useGetLicenseInfo({ fetchPolicy: 'network-only' });

  const [acceptEulaTC] = useAcceptEulaTC();
  const [acceptHumioTC] = useAcceptHumioTC();

  const isSSOLogin = JSON.parse(
    window.sessionStorage.getItem('isSSOLogin') as string,
  );

  const [lazyGetPrivileges, { loading: lazyGetPrivilegesLoading }] =
    useLazyGetPrivileges({
      fetchPolicy: 'network-only',
    });

  const { loading: getPrivilegesLoading, data: getPrivilegesData } =
    useGetPrivileges({
      skip: !user?.signInUserSession,
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (getPrivilegesData) {
      setUserPrivileges?.(JSON.parse(getPrivilegesData?.getPrivileges));
    }
  }, [getPrivilegesData, setUserPrivileges]);

  useEffect(() => {
    if (setUserPrivilegesLoading) {
      setUserPrivilegesLoading(
        getPrivilegesLoading || lazyGetPrivilegesLoading,
      );
    }
  }, [
    getPrivilegesLoading,
    lazyGetPrivilegesLoading,
    setUserPrivilegesLoading,
  ]);

  useEffect(() => {
    if (setUserLicenseLoading) {
      setUserLicenseLoading(getUserLicenseLoading);
    }
  }, [getUserLicenseLoading, setUserLicenseLoading]);

  useEffect(() => {
    if (user?.signInUserSession) {
      if (setUserAlias && user?.attributes['custom:alias']) {
        setUserAlias(user?.attributes['custom:alias']);
      }
      getAppConfigurations()
        .then(({ data }) => {
          const res_data = data?.getAppConfigurations?.body?.data ?? '{}';
          setGetAppConfigurationsData(res_data);
          setGetAppConfigurationsLoading(false);
        })
        .catch((err) => {
          setGetAppConfigurationsData(null);
          setGetAppConfigurationsLoading(false);
          console.error(err);
        });
      lazyGetPrivileges()
        .then(({ data }) => {
          if (data?.getPrivileges) {
            setUserPrivileges?.(JSON.parse(data?.getPrivileges));
          }
        })
        .catch((err) => {
          console.error(err);
        });

      getUserLicense()
        .then(({ data }) => {
          if (data?.getLicenseInfo) {
            setUserLicense?.(data?.getLicenseInfo);
            setIsOrgTenant?.(
              data?.getLicenseInfo?.federation_type === FEDERATION_TYPE.ORG,
            );
            setIsPOVTenant?.(
              data?.getLicenseInfo?.tenant_type === TENANT_TYPE.POV,
            );
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [user]);

  const ssoPrerequisites = async () => {
    const id_token = user?.signInUserSession?.idToken?.jwtToken;
    if (id_token) {
      const token = await setHumioSessionId(id_token, isPOVTenant).catch(
        (error) => console.error(error),
      );
      if (token && setSessionIdTokenCorelight) {
        setSessionIdTokenCorelight(token);
      }
    }
    if (isSSOLogin) {
      if (
        localStorage.getItem('accept_privacy_policy') &&
        localStorage.getItem('accept_privacy_policy') === 'true'
      ) {
        acceptEulaTC()
          .then(() => {
            localStorage.removeItem('accept_privacy_policy');
          })
          .catch((err) => {
            console.error(err);
          });
        logOperationCall('PRIVACY_POLICY_ACKNOWLEDGED', 'USER');
      }
      if (
        localStorage.getItem('humio_tc_accepted') &&
        localStorage.getItem('humio_tc_accepted') === 'true'
      )
        acceptHumioTC({
          variables: {
            humioSessionToken: localStorage.getItem('session_id_token') || '',
          },
        })
          .then(() => {
            localStorage.removeItem('humio_tc_accepted');
          })
          .catch((err) => {
            console.error(err);
          });
    }
  };

  useEffect(() => {
    if (userLicense && !userLicenseLoading) {
      ssoPrerequisites();
    }
  }, [user, isPOVTenant, userLicense, userLicenseLoading]);

  return (
    <DateRangeProvider>
      <AppRoutes
        user={user}
        isAdmin={userRole === 'admin'}
        isSSOLogin={isSSOLogin}
        preferredMFA={preferredMFA}
        setPreferredMFA={setPreferredMFA}
        getAppConfigurationsData={getAppConfigurationsData}
        getAppConfigurationsLoading={getAppConfigurationsLoading}
      />
    </DateRangeProvider>
  );
};
