import { Box, Flex, Text } from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { MuiIcon } from '@gamma/display';
import { i18n } from '@gamma/investigator/localization';
import { Column, Panel, PanelHeader } from '@gamma/layout';
import { Column as TableColumn } from 'react-table';
import './ConnHistoryDetails.css';
import { countCharacters } from './ConnHistorySummary';

const {
  historyIcon,
  history: historyConstants,
  connection,
  table,
} = i18n.pages.detections.logscaleDetails;

interface ConnHistoryDetailsProps {
  history: string;
  historySummary: string;
}

interface ConnHistoryActivity {
  icon: string;
  detail: string;
}

interface ConnHistoryDetailsTableColumn {
  activity: ConnHistoryActivity;
  sentBy: string;
}

export const ConnHistoryDetails = ({
  history,
  historySummary,
}: ConnHistoryDetailsProps) => {
  const iconValues: { [key: string]: string } = historyIcon;
  const historyDetails: { [key: string]: string } = historyConstants;

  const isUppercase = (character: string) => {
    return character === character.toUpperCase();
  };

  const charCounts = countCharacters(history);

  const columns: TableColumn<ConnHistoryDetailsTableColumn>[] = [
    {
      Header: table.activity,
      accessor: 'activity',
      disableSortBy: true,
      width: 350,
      Cell: (props) => {
        return (
          <Flex gap={2} alignItems="start">
            {props.value.icon === 'D' ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M3.02148 14.166V5.81185L10.0007 1.79102L16.9798 5.81185V10.541C16.7298 10.3466 16.4729 10.173 16.209 10.0202C15.9451 9.85352 15.6604 9.72157 15.3548 9.62435V7.83268L12.1465 9.68685C11.5215 9.81185 10.952 10.0688 10.4382 10.4577C9.92426 10.8466 9.51454 11.3118 9.20898 11.8535V10.4785L4.64648 7.81185V13.2077L8.52148 15.4785C8.60482 15.8674 8.72287 16.2493 8.87565 16.6243C9.04232 16.9993 9.2576 17.3466 9.52148 17.666C9.41037 17.6938 9.29232 17.6938 9.16732 17.666C9.05621 17.6382 8.95204 17.5966 8.85482 17.541L3.02148 14.166ZM10.0215 3.66602L5.29232 6.39518L10.0007 9.10352L14.709 6.39518L10.0215 3.66602Z"
                  fill="#C6C6C6"
                />
                <path
                  d="M13.6673 12.6257L11.4173 14.8757L12.1673 15.6048L13.1465 14.6257L13.1465 16.6048L14.209 16.6048L14.209 14.6257L15.1882 15.6048L15.9173 14.8757L13.6673 12.6257ZM13.6673 10.7506C14.7368 10.7506 15.6465 11.1257 16.3965 11.8757C17.1465 12.6257 17.5215 13.5354 17.5215 14.6048C17.5215 15.6743 17.1465 16.584 16.3965 17.334C15.6465 18.084 14.7368 18.459 13.6673 18.459C12.5979 18.459 11.6882 18.084 10.9382 17.334C10.1882 16.584 9.81315 15.6743 9.81315 14.6048C9.81315 13.5354 10.1882 12.6256 10.9382 11.8756C11.6882 11.1256 12.5979 10.7506 13.6673 10.7506Z"
                  fill="#C6C6C6"
                />
              </svg>
            ) : (
              <MuiIcon color="text.secondary">{props.value.icon}</MuiIcon>
            )}
            <Text>{props.value.detail}</Text>
          </Flex>
        );
      },
    },
    {
      Header: table.sentBy,
      accessor: 'sentBy',
      disableSortBy: true,
    },
  ];

  const data = Object.keys(charCounts).map((char) => {
    const sentBy = isUppercase(char) ? table.client : table.server;
    const charUpper = char.toUpperCase();
    let charIndex = '';
    if (char == 'd' || char == '^') {
      charIndex = char;
    } else {
      charIndex = charUpper;
    }
    const icon = charIndex === 'D' ? 'D' : iconValues[charIndex];
    let detail = '';
    if (['C', 'G', 'T', 'W', 'c', 'g', 't', 'w'].includes(char)) {
      if (charCounts[char] > 2) {
        detail = '>100 ' + historyDetails[charUpper];
      } else if (charCounts[char] > 1) {
        detail = '>10 ' + historyDetails[charUpper];
      } else {
        detail = historyDetails[charUpper];
      }
    } else {
      detail = historyDetails[charUpper];
    }
    return { activity: { icon, detail }, sentBy };
  });

  return (
    <Panel>
      <PanelHeader
        borderBottomWidth="1px"
        borderBottomColor={'border.layer.1'}
        data-testid="conn-history-details-header"
      >
        <Column>
          <Text textStyle="h5">{connection.history}</Text>
          <Text overflowWrap="anywhere" maxWidth="420px">
            {historySummary}.
          </Text>
        </Column>
      </PanelHeader>
      <Box w="full" data-testid="conn-history-details">
        <DataTable
          columns={columns}
          data={data}
          isPaginable={false}
          size="xs"
        />
      </Box>
    </Panel>
  );
};
