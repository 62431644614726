import {
  HStack,
  Text,
  Tooltip,
  useMultiStyleConfig,
  VStack,
} from '@chakra-ui/react';
import { SeverityScore } from '@gamma/investigator/components';
import { SlideDrawerContext } from '@gamma/investigator/context';
import { Detection } from '@gamma/investigator/queries';
import { useContext } from 'react';

interface RelatedDetectionRowProps {
  item: any;
  selectedDetection?: Detection;
  setSelectedDetectionId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const RelatedDetectionRow = ({
  item,
  selectedDetection,
  setSelectedDetectionId,
}: RelatedDetectionRowProps) => {
  const { setPortalType } = useContext(SlideDrawerContext);

  const isCustomSeverityScore = item?.rank?.is_custom_severity;

  const styles = useMultiStyleConfig('DataList', {
    layerStyle: 'first',
  });

  return (
    <HStack
      p={4}
      cursor="pointer"
      alignItems="center"
      data-testid="detection-row"
      borderTop="solid 1px"
      borderTopColor="border.1"
      __css={
        item.detection_id === selectedDetection?.detection_id
          ? styles.selectedItem
          : styles.item
      }
      onClick={() => {
        setPortalType?.('detection');
        setSelectedDetectionId(item.detection_id);
      }}
    >
      {item?.severity && (
        <SeverityScore isCustom={isCustomSeverityScore} score={item.severity} />
      )}
      <Tooltip label={`${item?.alert_name} ${item?.entity_name}`}>
        <VStack spacing={1} alignItems="start" whiteSpace="nowrap">
          <Text
            w="210px"
            overflow="hidden"
            textStyle="body-md"
            textOverflow="ellipsis"
          >
            {item?.alert_name}
          </Text>
          <Text
            w="210px"
            overflow="hidden"
            textStyle="body-md"
            color="text.secondary"
            textOverflow="ellipsis"
          >
            {item?.entity_name}
          </Text>
        </VStack>
      </Tooltip>
    </HStack>
  );
};
