import {
  AsyncSelect,
  GroupBase,
  InputActionMeta,
  MultiValue,
  OptionsOrGroups,
} from 'chakra-react-select';
import DOMPurify from 'dompurify';
import { useRef, useState } from 'react';
import { ActionMeta, SingleValue } from 'react-select';
import { ComboboxWrapper } from '../Components';
import { OptionType, useCombobox } from '../hooks';

export interface AsyncComboboxProps<
  Option extends OptionType = OptionType,
  Group extends GroupBase<Option> = GroupBase<Option>,
> {
  'data-testid'?: string;
  id?: string;
  cacheOptions?: boolean;
  isDisabled?: boolean;
  isMulti?: boolean;
  category: string;
  defaultOptions?: Option[];
  placeholder?: string;
  options: Option[];
  value: Option[];
  onChange: (
    value: MultiValue<Option> | SingleValue<Option>,
    action?: ActionMeta<Option>,
  ) => void;
  onOpen?: () => void;
  onClose?: () => void;
  /**
   * Function that returns a promise, which is the set of options to be used
   * once the promise resolves.
   */
  loadOptions: (inputValue: string) => Promise<Option[]> | Option[];
  size?: 'xs' | 'sm' | 'md';
}

export const AsyncCombobox = <
  Option extends OptionType,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncComboboxProps<Option, Group>,
) => {
  const {
    'data-testid': dataTestId = 'gamma-combobox',
    isMulti = true,
    size = 'md',
  } = props;
  const { wrapperProps, selectProps } = useCombobox<Option>({
    ...props,
    'data-testid': dataTestId,
    isMulti,
    placeholder: `Search ${props.category}`,
    size,
  });
  // remove options from the select props for async select
  const { options, ...selProps } = selectProps;
  const [loadedItems, setLoadedItems] = useState<Option[] | null>(null);

  const withSelectedOptions = (options: Option[]) => [
    ...props.value,
    ...options.filter(
      ({ value: optionValue }) =>
        !props.value.some(
          ({ value: selectedValue }) => optionValue === selectedValue,
        ),
    ),
  ];

  const [inputValue, setInputValue] = useState<string>('');

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleLoadOptions = async (value: string) => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    return new Promise<OptionsOrGroups<Option, Group>>((resolve) => {
      timeoutRef.current = setTimeout(async () => {
        const loadedValues = await props.loadOptions(DOMPurify.sanitize(value));
        setLoadedItems(loadedValues);
        resolve(withSelectedOptions(loadedValues));
      }, 500);
    });
  };

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (actionMeta.action === 'input-change') {
      setInputValue(newValue);
    }
    return newValue;
  };

  return (
    <ComboboxWrapper
      {...wrapperProps}
      onOpen={() => {
        setInputValue('');
        wrapperProps.onOpen();
      }}
      onClearAll={() => {
        setInputValue('');
        wrapperProps.onClearAll();
      }}
    >
      <AsyncSelect
        {...selProps}
        loadOptions={handleLoadOptions}
        defaultOptions={
          inputValue
            ? withSelectedOptions(loadedItems || [])
            : withSelectedOptions(props.defaultOptions || [])
        }
        onInputChange={handleInputChange}
        inputValue={inputValue}
        cacheOptions
      />
    </ComboboxWrapper>
  );
};
