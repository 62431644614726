import { ReactNode, useMemo, useState } from 'react';
import { SlideDrawerContext } from './SlideDrawerContext';

interface SlideDrawerProviderProps {
  children?: ReactNode;
}

export const SlideDrawerProvider = ({ children }: SlideDrawerProviderProps) => {
  const [portalType, setPortalType] = useState<
    'alert' | 'entity' | 'detection' | undefined
  >();
  const [isSlideDrawerOpen, setIsSlideDrawerOpen] = useState<
    boolean | undefined
  >();
  const [slideDrawerTitle, setSlideDrawerTitle] = useState<Element | null>(
    null,
  );
  const [slideDrawerBody, setSlideDrawerBody] = useState<Element | null>(null);
  const [slideDrawerActions, setSlideDrawerActions] = useState<Element | null>(
    null,
  );
  const [slideDrawerOnClose, setSlideDrawerOnClose] = useState(() => {
    return () => console.log('Initial function called');
  });

  const slideDrawerContext = useMemo(() => {
    return {
      portalType,
      setPortalType,
      slideDrawerTitle,
      setSlideDrawerTitle,
      isSlideDrawerOpen,
      setIsSlideDrawerOpen,
      slideDrawerBody,
      setSlideDrawerBody,
      slideDrawerActions,
      setSlideDrawerActions,
      slideDrawerOnClose,
      setSlideDrawerOnClose,
    };
  }, [
    portalType,
    slideDrawerTitle,
    isSlideDrawerOpen,
    slideDrawerBody,
    slideDrawerActions,
    slideDrawerOnClose,
  ]);
  return (
    <SlideDrawerContext.Provider value={{ ...slideDrawerContext }}>
      {children}
    </SlideDrawerContext.Provider>
  );
};
