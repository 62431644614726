import {
  Badge,
  Box,
  Center,
  HStack,
  Tag,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { DataTable } from '@gamma/data-table';
import { SeverityScore } from '@gamma/investigator/components';
import { ROUTES } from '@gamma/investigator/constants';
import { AuthContext, SlideDrawerContext } from '@gamma/investigator/context';
import { i18n } from '@gamma/investigator/localization';
import {
  Detection,
  IQueryDetectionsPaginated,
} from '@gamma/investigator/queries';
import { stringifyAlertType } from '@gamma/investigator/utilities';
import { LoadingSpinner } from '@gamma/progress';
import moment from 'moment';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { NavigateFunction, useParams } from 'react-router-dom';
import { SortingRule, Column as TableColumn } from 'react-table';

import { SantizedDetection } from '../../Detections';
import { AlertEntityPopover } from '../../Pages';
import { DetectionDrawerActions } from '../DetectionDrawer';
import { SendToPill } from '../DetectionPills';
import { DetectionStatusPill } from '../DetectionStatusPill';

const { detections, detectionDetails } = ROUTES;

const { controls, detection } = i18n.pages.detections;
const { unassigned, externalTenant } = detection;

const dataTableColumns: TableColumn<SantizedDetection>[] = [
  {
    width: 100,
    Header: i18n.tables.score,
    accessor: 'rank.severity',
    Cell: (props) => {
      return (
        <SeverityScore
          score={props.value}
          isCustom={props.row.original.rank.is_custom_severity}
        />
      );
    },
  },
  {
    Header: i18n.tables.detection,
    accessor: 'alert_info.alert_name',
    disableSortBy: true,
  },
  {
    width: 175,
    Header: 'Source',
    accessor: 'paginated_source_entities',
    disableSortBy: true,
    Cell: (props) => (
      <AlertEntityPopover
        entities={props?.value?.alert_entities?.map(
          (entity) => entity.entity_name,
        )}
      />
    ),
  },
  {
    width: 175,
    Header: 'Destination',
    accessor: 'paginated_destination_entities',
    disableSortBy: true,
    Cell: (props) => (
      <AlertEntityPopover
        entities={props?.value?.alert_entities?.map(
          (entity) => entity.entity_name,
        )}
      />
    ),
  },
  {
    width: 150,
    Header: i18n.tables.tenant,
    accessor: 'tenant_info.tenant_display_name',
    disableSortBy: true,
    Cell: (props) => (
      <Badge isTruncated variant="outline" maxW="100%">
        {props.value}
      </Badge>
    ),
  },
  {
    width: 150,
    Header: i18n.tables.alertType,
    accessor: 'alert_info.alert_type',
    disableSortBy: true,
    Cell: (props) => {
      return <>{stringifyAlertType(props.value)}</>;
    },
  },
  {
    width: 100,
    Header: i18n.tables.numAlerts,
    accessor: 'total_alert_count',
    disableSortBy: true,
  },
  {
    width: 150,
    Header: i18n.tables.status,
    accessor: 'detection_status',
    disableSortBy: true,
    Cell: (props) => {
      return (
        <HStack>
          <DetectionStatusPill
            text={props.value}
            isStatusOpen={props.value === 'open'}
          />
          {props?.row?.original?.escalation_data?.escalation_status && (
            <SendToPill
              escalation_data={props?.row?.original?.escalation_data}
            />
          )}
        </HStack>
      );
    },
  },
  {
    width: 150,
    Header: i18n.tables.assignee,
    accessor: 'assignment_info.assigned_to_user_alias',
    disableSortBy: true,
    Cell: (props) => {
      return (
        <Tag
          size="md"
          variant="solid"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          data-testid="detection-alert-assignee"
          colorScheme={props?.value ? 'purple' : 'gray'}
        >
          <Tooltip
            label={
              !props?.value
                ? unassigned
                : props?.value === 'External User'
                  ? externalTenant
                  : props?.value
            }
          >
            <Text isTruncated>{!props?.value ? unassigned : props?.value}</Text>
          </Tooltip>
        </Tag>
      );
    },
  },
  {
    Header: i18n.tables.lastAlertTime,
    accessor: 'latest_start_timestamp',
    Cell: (props) => {
      return <>{moment.unix(props.value).format('MMMM Do, h:mma')}</>;
    },
  },
];

interface DetectionsTableViewProps {
  offset: number;
  pageSize: number;
  pageIndex: number;
  pageCount: number;
  queryVariables: any;
  sort_by: string | null;
  isRelated?: boolean;
  params: URLSearchParams;
  navigate: NavigateFunction;
  detectionsLoading: boolean;
  detectionsData?: IQueryDetectionsPaginated;
  selectedItem?: Detection | SantizedDetection;
  setIsExcluded: Dispatch<SetStateAction<boolean>>;
  formatSort: (value: string) => { id: string; desc: boolean }[];
  handleFetchData: (pageIndex: number, pageSize: number, sortBy?: any) => void;
  setSelectedItem: Dispatch<
    SetStateAction<Detection | SantizedDetection | undefined>
  >;
  getSortsForAPI: (
    sortBy: SortingRule<Detection>[],
  ) => { sort_by: string; sort_dir: string }[];
}

export const DetectionsTableView = ({
  params,
  isRelated,
  navigate,
  offset,
  sort_by,
  pageSize,
  pageIndex,
  pageCount,
  formatSort,
  setIsExcluded,
  getSortsForAPI,
  queryVariables,
  detectionsData,
  handleFetchData,
  selectedItem,
  setSelectedItem,
  detectionsLoading,
}: DetectionsTableViewProps) => {
  const { isOrgTenant } = useContext(AuthContext);

  const { setIsSlideDrawerOpen } = useContext(SlideDrawerContext);

  const hiddenCols = [
    'detection_id',
    ...(!isOrgTenant ? ['tenant_info.tenant_display_name'] : []),
  ];

  const active_view = params.get('view');

  const { detection_id } = useParams();

  useEffect(() => {
    if (active_view !== 'table') {
      params.set('view', 'table');
      navigate(
        isRelated && detection_id
          ? {
              pathname: detectionDetails(detection_id),
              search: params.toString(),
            }
          : {
              pathname: detections,
              search: params.toString(),
            },
        {
          replace: true,
        },
      );
    }
  }, [active_view]);

  const filteredDataTableColumns = useMemo(
    () =>
      dataTableColumns.filter(
        (col) => !hiddenCols.includes(col.accessor as string) && col,
      ),
    [dataTableColumns, hiddenCols],
  );

  const sanitizedDetectionsData =
    detectionsData?.queryDetectionsPaginated?.detections?.map(
      ({
        rank,
        tenant_info,
        alert_info,
        update_info,
        alert_entity,
        detection_id,
        assignment_info,
        escalation_data,
        detection_status,
        total_alert_count,
        latest_start_timestamp,
        earliest_start_timestamp,
        paginated_source_entities,
        paginated_destination_entities,
      }) => {
        return {
          rank,
          tenant_info,
          alert_info,
          update_info,
          alert_entity,
          detection_id,
          assignment_info,
          escalation_data,
          detection_status,
          total_alert_count,
          latest_start_timestamp,
          earliest_start_timestamp,
          paginated_source_entities,
          paginated_destination_entities,
        };
      },
    ) || [];

  return (
    <Box
      borderTop="1px solid"
      borderColor="border.layer.2"
      data-testid="data-table-wrapper"
    >
      <DataTable
        isExpandable
        // @ts-ignore
        pageSize={pageSize}
        pageCount={pageCount}
        autoResetSortBy={false}
        isPaginationManual={true}
        onFetchData={handleFetchData}
        onRowClick={(item) => {
          setSelectedItem(item?.original as SantizedDetection);
          setIsSlideDrawerOpen?.(true);
        }}
        // @ts-ignore
        columns={filteredDataTableColumns}
        emptyBodyContent={
          detectionsLoading ? (
            <LoadingSpinner />
          ) : detectionsData?.queryDetectionsPaginated?.total_items === 0 ? (
            <Center>{controls.noResults}</Center>
          ) : (
            <></>
          )
        }
        autoResetPage={offset / pageSize >= pageCount}
        data={!detectionsLoading ? sanitizedDetectionsData : []}
        itemCount={detectionsData?.queryDetectionsPaginated?.total_items}
        initialState={{
          // @ts-ignore
          pageIndex,
          sortBy: sort_by
            ? formatSort(sort_by)
            : formatSort(queryVariables?.sortBy),
        }}
        RowActions={(detectionData) =>
          queryVariables &&
          !detectionsLoading && (
            <DetectionDrawerActions
              isRowActions
              isRelated={isRelated}
              detection={detectionData}
              setIsExcluded={setIsExcluded}
              queryDetectionsPaginatedVariables={{
                query: queryVariables.query,
                size: queryVariables.size,
                offset: queryVariables.offset,
                sort: getSortsForAPI(formatSort(queryVariables.sortBy)),
              }}
            />
          )
        }
      />
    </Box>
  );
};
